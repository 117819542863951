import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}

  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundImage: `url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(
          '#fff'
        )}" ><path d="M0 0h24v24H0z" fill="none"/><path d="M21 7L9 19l-5.5-5.5L5.91 12.5 9 15.59 18.59 6z"/></svg>')`,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundImage: `url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(
        '#fff'
      )}" ><path d="M0 0h24v24H0z" fill="none"/><path d="M21 7L9 19l-5.5-5.5L5.91 12.5 9 15.59 18.59 6z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function IOSSwitchComponent({isEditing,handleChangeSwitch,switchName,checkedWtitch}) {
  const [checked, setChecked] = React.useState(false);

  useEffect(()=>{
    console.log(checkedWtitch)
    setChecked(checkedWtitch);
  },[checkedWtitch])

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleChangeSwitch(event.target.checked,switchName);
  };

  return (
    <div>
      <IOSSwitch disabled={isEditing} name={switchName} checked={checkedWtitch} onChange={handleChange} />
    </div>
  );
}
