import React, { Fragment, useEffect, useState } from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import ListComponent from '../components/common/list-component'
import LeftSideListComponent from '../components/home-page-component/left-side-list-component'
import { Footer } from 'rsuite'
import FooterComponent from '../components/common/footer-component/footer-component'
import SectionHeaderCompnent from '../components/home-page-component/section-header-component/section-header-component'
import SectionOneComponent from '../components/home-page-component/section-one/section-one-component'
import SectionTwoComponent from '../components/home-page-component/section-two/section-two-component'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import axios, { BaseUrl } from '../api/axios'
import Cookies from 'universal-cookie'
import CardListComponent2 from '../components/home-page-component/card-list-component-2'
import SkeletonTypography from '../components/common/skelton/skelton-one'


export default function HomePage() {

  const privateAxisos = useAxiosPrivate();
  const [initialData, setInitialData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();


useEffect(()=>{

  setIsLoading(true);
  axios.post("/dashboard-initial-data")
      .then(async function (response) {
        if(response.status === 200){
        //  console.log(response.data)
          cookies.set('sjpEmail', response?.data?.sjpEmail, { path: '/' });
          cookies.set('empNo', response?.data?.empNo, { path: '/' });
            setInitialData(response?.data);
            console.log(response?.data)
            setIsLoading(false);
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
         // console.log(error);
        }, 2000);
      });
},[])

useEffect(()=>{
     // const access_token = localStorage.getItem("authToken");
     // console.log(access_token);


      axios.get(`${BaseUrl}/user`)
      .then(async function (response) {
        //  console.log("recive response");
         //console.log(response?.data);
        if(response.status === 200){
         // console.log("success");
          //console.log(response?.data);
          if(response?.status === 200){
           // console.log(response?.data);
          }else{
            //console.log("fail get access token");
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
         // console.log(error);
        }, 2000);
      });


    },[])

  return (
    <Fragment>
        <NavbarComponent />
        
        <SectionHeaderCompnent isLoading={isLoading} initialData={initialData}/>
        {/* <SectionOneComponent /> */}
     
    {(
        (initialData?.recommendationList?.length > 0) || 
        (initialData?.approvalList?.length > 0)
      ) && !isLoading && (
        <SectionTwoComponent isLoading={isLoading} initialData={initialData} />
      )}

        

        <CardListComponent2 isLoading={isLoading} />

        <FooterComponent />
    </Fragment>
   
  )
}
