import React, { Fragment, useEffect, useState } from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import { Button, Divider, Panel, Placeholder, RadioTile, RadioTileGroup, Steps } from 'rsuite'
import { Col, Form, Row } from 'react-bootstrap'
import StepsComponent from '../components/create-account-component/steps-component'
import UserVerificationForm from '../components/create-account-component/user-verification-form'
import UserDetailsPanel from '../components/create-account-component/user-details-panel'
import MobileVerificationComponent from '../components/create-account-component/mobile-verification-component'
import { Icon } from '@rsuite/icons';
import EmailVerificationComponent from '../components/create-account-component/email-verification-component'
import VerificationForm from '../components/create-account-component/verification-form'
import CreateNewPasswordForm from '../components/create-account-component/create-new-password-form'
import SuccessForm from '../components/create-account-component/success-form'
import axios from '../api/axios'


export default function CreateAccountPage() {


const [userData, setUserData] = useState({
    name: "",
    fname:"",
    lname:"",
    department: "",
    faculty: "",
    empNo: '',
    nic: '',
    otp:'',
    verifyOtp:'',
    verificationType:'',
    verification_status: false,
    stepCount: 0,
    email:"",
    userType: "",
    mobileNo: "",
    password: '',
    confirmPassword: '',
    batch:"",
    facultyCode: "",
    lecureOrNot:"",
  });
  const [showEmpRequestEmail, setShowEmpRequestEmail] = useState(false);
  const [showVerificationMethod, setShowVerificationMethod] = useState(false);
  const [showInvalidEmailAddress, setShowInvalidEmailAddress] = useState(false);
  const [showStudentHaventEmailAddress, setShowStudentHaventEmailAddress] = useState(false);
  const [showPendingEmailRequest, setPenidngEmailRequest] = useState(false);
   const [showMailRequestSuccessMessage, setShowMailRequestSuccessMessage] = useState(false);
   const [showAlreadyMemberMessage, setShowAlreadyMemberMessage] = useState(false);
   const [isLoading, setIsLoading] = useState(true);


 // Step 2: Handle changes to the data
  const handleChange = (field, value) => {
    setUserData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const handleOnSubmitUserValidation = async () => {  

    var submitData = {
      'empNo' : userData?.empNo,
      'nic' :userData?.nic,
    };

    axios.post("/user-validation",submitData)
      .then(async function (response) {
        if(response.status === 200){
          console.log(response.data);
          if(response?.data?.state === "success"){
                setShowVerificationMethod(true);
                setShowEmpRequestEmail(false);
                setShowInvalidEmailAddress(false);
                setShowStudentHaventEmailAddress(false);
                setPenidngEmailRequest(false);
                setShowAlreadyMemberMessage(false);

            if(response?.data?.code === 'uv1001'){ // employee havent email address
                setShowVerificationMethod(false);
                setShowEmpRequestEmail(true);
                setShowInvalidEmailAddress(false);
                setShowStudentHaventEmailAddress(false);
                setPenidngEmailRequest(false);
                setShowAlreadyMemberMessage(false);
                

            }else if(response?.data?.code === 'uv1002'){//employeer invalid email address
                setShowVerificationMethod(false);
                setShowEmpRequestEmail(false);
                setShowInvalidEmailAddress(true);
                setShowStudentHaventEmailAddress(false);
                setPenidngEmailRequest(false);
                setShowAlreadyMemberMessage(false);

            }else if(response?.data?.code === 'uv1003'){//Student havent email address

                setShowVerificationMethod(false);
                setShowEmpRequestEmail(false);
                setShowInvalidEmailAddress(false);
                setShowStudentHaventEmailAddress(true);
                setPenidngEmailRequest(false);
                setShowAlreadyMemberMessage(false);

            }else if(response?.data?.code === 'uv1004'){//Student invalid credentials

                setShowVerificationMethod(false);
                setShowEmpRequestEmail(false);
                setShowInvalidEmailAddress(false);
                setShowStudentHaventEmailAddress(true);
                setPenidngEmailRequest(false);
                setShowAlreadyMemberMessage(false);

            }else if(response?.data?.code === 'uv1005'){//invalid credentials
                setShowVerificationMethod(false);
                setShowEmpRequestEmail(false);
                setShowInvalidEmailAddress(false);
                setShowStudentHaventEmailAddress(false);
                setPenidngEmailRequest(false);
                setShowAlreadyMemberMessage(false);

            }else if(response?.data?.code === 'uv1006'){//iprocessing request
                setShowVerificationMethod(false);
                setShowEmpRequestEmail(false);
                setShowInvalidEmailAddress(false);
                setShowStudentHaventEmailAddress(false);
                setPenidngEmailRequest(true);
                setShowAlreadyMemberMessage(false);

            }else if(response?.data?.code === 'uv1000'){//invalid credentials
                setShowVerificationMethod(false);
                setShowEmpRequestEmail(false);
                setShowInvalidEmailAddress(false);
                setShowStudentHaventEmailAddress(false);
                setPenidngEmailRequest(false);
                setShowAlreadyMemberMessage(true);

            }


            handleChange("name",response?.data?.name);
            handleChange("fname",response?.data?.fname);
            handleChange("lname",response?.data?.lname);
            handleChange("department",response?.data?.department);
            if(response?.data?.code !== 'uv1001' && response?.data?.code !== 'uv1006' ){
              handleChange("email",response?.data?.email);
            }
            
            handleChange("faculty",response?.data?.faculty);
            handleChange("userType",response?.data?.userType);
            handleChange("mobileNo",response?.data?.mobileNo);
            handleChange('stepCount',1);
            handleChange("batch",response?.data?.batch);
          }else{
            alert("invalid credentials");
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

  const handleOnSubmitSendVerification = async () => {
    
    var submitData = {
      'email' : userData?.email,
      'verificationType' :userData?.verificationType,
      'mobileNo': userData?.mobileNo, 
    };


    axios.post("/send-verification",submitData)
      .then(async function (response) {
     
        if(response.status === 200){
          if(response?.data?.state === "success"){
            // handleChange('stepCount',2);
            // handleChange('verification_status',true);
          }else{
            alert(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

   const handleOnSubmitVerifyVerification = async () => {
    
    var submitData = {
      'email' : userData?.email,
      'otp' :userData?.otp,
      'mobileNo': userData?.mobileNo, 
    };

    axios.post("/user-validation-otp",submitData)
      .then(async function (response) {
        if(response.status === 200){
          if(response?.data?.state === "success"){
            handleChange('stepCount',2);
            handleChange('verification_status',true);
          }else{
            alert(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

   const handleOnSubmitCreateNewAccount = async () => {
    
    var submitData = {
      'fname' : userData?.fname,
      'lname' : userData?.lname,
      'email' : userData?.email,
      'empNo' : userData?.empNo,
      'batch' : userData?.batch,
      'facultyName' : userData?.faculty,
      'tp' : userData?.mobileNo,
      'department': userData?.password,
      'typeOfApplyer': userData?.userType,
      'faculty_code': userData?.facultyCode,
      'password' : userData?.password,
      'confirmPassword' :userData?.confirmPassword,
      "lecureOrNot": userData?.lecureOrNot,
    };
    

    axios.post("/user-create-new-account",submitData)
      .then(async function (response) {
        if(response.status === 200){
          if(response?.data?.state === "success"){
            handleChange('stepCount',3);
          }else{
            alert(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

   const handleOnSubmitApplyNewEmail = async (details) => {
    
    var submitData = {
      'employee_no': userData.empNo,
      'preferred_user_name_1' : details?.preferredUserName1,
      'preferred_user_name_2' : details?.preferredUserName2,
      'alternate_email_address' : details?.alternateEmailAddress,
    };

    axios.post("/user-apply-new-email",submitData)
      .then(async function (response) {
        if(response.status === 200){
          if(response?.data?.state === "success"){
            setShowMailRequestSuccessMessage(true);
          }else{
            console.log(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

   const handleOnSubmitRequestEmail = async (details) => {
    
    var submitData = {
      'employee_no': userData.empNo,
      'sjpEmailAddress' : details?.emailAddress,
      
    };
    
    axios.post("/user-request-email",submitData)
      .then(async function (response) {
        if(response.status === 200){
          if(response?.data?.state === "success"){
            setShowMailRequestSuccessMessage(true);
          }else{
            console.log(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }



  return (
    <Fragment>
         <NavbarComponent />

    <section>
        <div class="container">
            <div><h3>Create New Account</h3></div>
            <div class="row">
                <div className='mt-3'>
                    <StepsComponent stepCount={userData?.stepCount} />
                </div>
                    
                
                {userData?.stepCount === 0 && (<UserVerificationForm handleChange={handleChange} userDetails={userData} handleOnSubmitUserValidation={handleOnSubmitUserValidation} />)}

                {userData?.stepCount === 1 && (
                    <VerificationForm validationPath="createNewAccount" handleOnSubmitRequestEmail={handleOnSubmitRequestEmail} handleOnSubmitApplyNewEmail={handleOnSubmitApplyNewEmail} handleChange={handleChange} userDetails={userData} handleOnSubmitSendVerification={handleOnSubmitSendVerification} handleOnSubmitVerifyVerification={handleOnSubmitVerifyVerification} showVerificationMethod={showVerificationMethod} showEmpRequestEmail={showEmpRequestEmail} showInvalidEmailAddress={showInvalidEmailAddress} showStudentHaventEmailAddress={showStudentHaventEmailAddress} showPendingEmailRequest={showPendingEmailRequest} showMailRequestSuccessMessage={showMailRequestSuccessMessage} showAlreadyMemberMessage={showAlreadyMemberMessage}/>
                )}

                {userData?.stepCount === 2 && (
                    <CreateNewPasswordForm handleOnSubmitChangePassword={handleOnSubmitCreateNewAccount} handleChange={handleChange} userDetails={userData}/>
                )}
                
                {userData?.stepCount === 3 && (
                    <SuccessForm  handleChange={handleChange} userDetails={userData}/>
                )}
                
                
            </div>
        </div>
    </section>

  
    </Fragment>
  )
}
