import React, { Fragment, useEffect, useState } from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import ListComponent from '../components/common/list-component'
import LeftSideListComponent from '../components/home-page-component/left-side-list-component'
import { Breadcrumb, Footer } from 'rsuite'
import FooterComponent from '../components/common/footer-component/footer-component'
import SectionHeaderCompnent from '../components/home-page-component/section-header-component/section-header-component'
import SectionOneComponent from '../components/home-page-component/section-one/section-one-component'
import SectionTwoComponent from '../components/home-page-component/section-two/section-two-component'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import axios from '../api/axios'
import DynamicIframeComponent from '../components/dynamic-iframe-component/dynamic-iframe-component'
import { Link, useNavigate } from 'react-router-dom'

export default function DetailsPage() {

  const privateAxisos = useAxiosPrivate();
  const [initialData, setInitialData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
useEffect(()=>{

  setIsLoading(true);
  axios.post("/dashboard-initial-data")
      .then(async function (response) {
        if(response.status === 200){
            setInitialData(response?.data);
            console.log(response?.data)
            setIsLoading(false);
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
},[])

useEffect(()=>{
     // const access_token = localStorage.getItem("authToken");
     // console.log(access_token);


      axios.get("https://usjnetsso.sjp.ac.lk/sso/api/user")
      .then(async function (response) {
         // console.log("recive response");
        // console.log(response?.data);
        if(response.status === 200){
         // console.log("success");
         // console.log(response?.data);
          if(response?.status === 200){
          //  console.log(response?.data);
          }else{
          //  console.log("fail get access token");
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });


    },[])

  return (
    <Fragment>
        <NavbarComponent />
        <div class="container">
          <div>
            <Breadcrumb separator={">"}>
                <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
                Home
                </Breadcrumb.Item>
                <Breadcrumb.Item  >
                Salary
                </Breadcrumb.Item>
            </Breadcrumb>
        </div>
        
        <DynamicIframeComponent />
        
       
        </div>
         <FooterComponent />
    </Fragment>
   
  )
}
