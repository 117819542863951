import React, { Fragment, useEffect, useState } from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import ListComponent from '../components/common/list-component'
import LeftSideListComponent from '../components/home-page-component/left-side-list-component'
import { Breadcrumb, Footer, IconButton } from 'rsuite'
import FooterComponent from '../components/common/footer-component/footer-component'
import SectionHeaderCompnent from '../components/home-page-component/section-header-component/section-header-component'
import SectionOneComponent from '../components/home-page-component/section-one/section-one-component'
import SectionTwoComponent from '../components/home-page-component/section-two/section-two-component'
import findMemberImage from "../assets/findMembers.png";
import emailCreateImage from "../assets/email.png";
import memberImage from "../assets/members.png";
import AdminImgage from "../assets/admin.png";
import styled from 'styled-components'
import FindUserComponent from '../components/admin/section-one/find-user-component'
import USJNetMembersComponent from '../components/admin/section-one/usjnet-members-component'
import UserEmailCreateComponent from '../components/admin/section-one/user-email-creation-component'

import axios from '../api/axios'
import { Link } from 'react-router-dom'
import USJNetStudentMembersComponent from '../components/admin/section-one/usjnet-student-members-component'
import useServices from '../hooks/useServices'
import SetupHomePageComponent from '../components/admin/section-one/setup-home-page-component'
  

const StyledLink = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    color: #990000;
    text-decoration: none;
    font-size: 15px;
  }
`;

export default function AdminPage() {

    const [showVlaue, setShowValue] = useState("usjnetStaffMember");
    const [services] = useServices();

const onClickAction =(val) => {
    setShowValue(val);
}

const [isLoading, setIsLoading] = useState(true);
const [emailCreateData,setEmailCreateData] = useState([]);



  return (
    <Fragment>
        <NavbarComponent />
     <div class="container">
    <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item href='/home'>
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item >
            Admin Panel
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
    </div>
           
    <section class="">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-5">
                    <div class="sidebar">
                        <div class="sidebar__item">
                            <div style={{ display:"flex",flexDirection:"row" }}><img src={AdminImgage} alt="logo" width={30}  height={30} style={{ marginRight:"20px",marginTop:"10px" }}/><h4>Admin Panel</h4></div>
                            
                            <ul>
                                {/* <li> <div style={{ display:"flex",flexDirection:"row" }}><img src={findMemberImage} alt="logo" width={20}  height={20} style={{ marginRight:"20px",marginTop:"10px" }}/><StyledLink onClick={()=>onClickAction("findUser")} href="#">Find User</StyledLink></div> </li> */}
                                 
                                 {(services?.userInitialData?.userRole === 2 || services?.userInitialData?.userRole === 4 || services?.userInitialData?.userRole === 3) && (<li> <div style={{ display:"flex",flexDirection:"row" }}><img src={memberImage} alt="logo" width={20}  height={20} style={{ marginRight:"20px",marginTop:"10px" }}/><StyledLink onClick={()=>onClickAction("usjnetStaffMember")} href="#">Staff Members</StyledLink></div> </li>)}
                                 {(services?.userInitialData?.userRole === 2 || services?.userInitialData?.userRole === 3) && (<li> <div style={{ display:"flex",flexDirection:"row" }}><img src={memberImage} alt="logo" width={20}  height={20} style={{ marginRight:"20px",marginTop:"10px" }}/><StyledLink onClick={()=>onClickAction("usjnetStudentMember")} href="#">Student Members</StyledLink></div> </li>)} 
                                 {(services?.userInitialData?.userRole === 2 || services?.userInitialData?.userRole === 4) && (<li> <div style={{ display:"flex",flexDirection:"row" }}><img src={emailCreateImage} alt="logo" width={20}  height={20} style={{ marginRight:"20px",marginTop:"10px" }}/><StyledLink onClick={()=>onClickAction("emailCreation")} href="#">Email Requests</StyledLink></div> </li>)} 
                                
                                {/* <li> <div style={{ display:"flex",flexDirection:"row" }}><img src={emailCreateImage} alt="logo" width={20}  height={20} style={{ marginRight:"20px",marginTop:"10px" }}/><StyledLink onClick={()=>onClickAction("setupHomePage")} href="#">Set-Up Home Page</StyledLink></div> </li> */}

                            </ul>
                        </div>
                    
                    </div>
                </div>
                <div class="col-lg-9 col-md-7">
                   
                  {/* {showVlaue === "findUser" && (<FindUserComponent />)} */}
                  {showVlaue === "usjnetStaffMember" && (services?.userInitialData?.userRole === 2 || services?.userInitialData?.userRole === 4 || services?.userInitialData?.userRole === 3) && (<USJNetMembersComponent />)}
                  {showVlaue === "usjnetStudentMember" && (services?.userInitialData?.userRole === 2 || services?.userInitialData?.userRole === 3) && (<USJNetStudentMembersComponent />)}
                  {showVlaue === "emailCreation" && (services?.userInitialData?.userRole === 2 || services?.userInitialData?.userRole === 4) && (<UserEmailCreateComponent />)}
                  {/* {showVlaue === "setupHomePage" && (<SetupHomePageComponent />)} */}
                </div>
            </div>
        </div>
    </section>

    
   

        <FooterComponent />
    </Fragment>
   
  )
}
