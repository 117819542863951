import React, { useEffect, useState } from 'react'
import DataTableComponent from '../../common/data-table-component/data-table-component';
import { useSearch } from 'rsuite/esm/internals/Picker';
import axios from '../../../api/axios';
import { Button, Input, TextField } from '@mui/material';


export default function UserEmailCreateComponent() {

const [rowData, setRowData] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [emailCreateData,setEmailCreateData] = useState([]);

useEffect(()=>{

  setIsLoading(true);
  axios.get("/create-new-email-details")
      .then(async function (response) {
        if(response.status === 200){
            
            var data =response.data.map((val)=>({
                ...val,
                newSjpEmailAddress: '', 
            }));
            setEmailCreateData(data)
            setIsLoading(false);
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
},[]);

const handleChangeEmail = (e, indexNo) => {

    const data = emailCreateData.map((val, index) => {
        // Check if the current index matches the index we're updating
        if (index === indexNo) {
            return {
                ...val,
                newSjpEmailAddress: e.target.value, // Update the email address
            };
        }
        // Return the existing value for other indices
        return val;
    });

    // Update the state with the new data
    setEmailCreateData(data);
    
};

const handleSubmitCreateEmail = (indexNo) =>{
  
  const submittedDataArray = emailCreateData.map((val, index) => {
        if (index === indexNo) {
            return {
                sjp_email: val?.newSjpEmailAddress,
                empNo: val?.personal_details[0].employee_no
            };
        }
        return null;
    }).filter(item => item !== null);

const subbmittedDataObject = submittedDataArray.reduce((acc, item) => {
    acc["sjp_email"] = item.sjp_email;
    acc["empNo"] = item.empNo; // Use empNo as the key
    return acc;
}, {});
console.log(subbmittedDataObject)
  setIsLoading(true);
  axios.post("/admin/user-update/email",subbmittedDataObject)
      .then(async function (response) {
        console.log(response.data);
        // if(response.status === 200){
        //   if(response?.data?.status === 'success'){
        //       alert("updated");
        //   }else{
        //     alert("not updated");
        //   }
            
        //     setIsLoading(false);
        // }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });

}

  const formatString = (str) => {
    return str.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };


const columns = [
  { id: 'id', label: 'ID', width: 10 },
  { id: 'requestDate', label: 'Requested Date', width: 140 },
  { id: 'details', label: 'Details', minWidth: 200 },
  { id: 'preferredUserName', label: 'Preferred User Name', width: 100 },
  { id: 'extraDetails', label: 'Extra Details', maxWidth: 100 },
  { id: 'sjpEmail', label: 'SJP Mail', minWidth:270 },
  { id: 'action', label: 'Action', width: 170 },
  ];

  useEffect(()=>{
    var res =emailCreateData.map((val,index)=>({
        id : index+1,
        requestDate: val?.request_date,
        details: formatString(val?.personal_details[0]?.employee_no+"\n"+val?.extra_details?.designation_name+"\n"+val?.personal_details[0]?.employee_work_type+"\n"+"Terminate Date: "+val?.personal_details[0]?.salary_termination_date_1+"\n"+val?.personal_details[0]?.department_name+"\n"+val?.personal_details[0]?.faculty_name),
        preferredUserName:formatString("Name 01 - "+val?.preferred_user_name?.preferred_user_name_1+"\n"+"Name 02 - "+val?.preferred_user_name?.preferred_user_name_2),
        extraDetails:formatString(val?.extra_details?.name_denoted_by_initials+"\n"+val?.extra_details?.title_name+" "+val?.extra_details?.initials+" "+val?.extra_details?.last_name+"\n"+val?.extra_details?.alternate_email_address+"\n"+val?.extra_details?.mobile_no+"\n"+ val?.extra_details?.current_sjp_mail),
        sjpEmail:<><TextField
              label="Email"
              variant="outlined" // This makes the input outlined
               value={val?.newSjpEmailAddress}
               onChange={(val)=>handleChangeEmail(val,index)}
              fullWidth // Optional: to make the input take full width
            /></>,
        action:<>
          <Button
            variant="contained" // Makes the button filled
            color="primary" // Optional: Use a theme color like "primary" or "secondary"
            size="medium"   // Optional: "small", "medium", or "large"
            onClick={() => handleSubmitCreateEmail(index)}
          >
            Update
          </Button>
        </>
    }));
    setRowData(res);

  },[emailCreateData])

  // const data = [{ id: 1, requestDate: '2024-05-10', empNo: '300032', name: 'Mr.. W.M.D. Priyankara', faculty: 'Faculty of Technology', action:"action" },{ id: 2, firstName: 'John', lastName: 'Doe' }];

   const renderRowExpanded = rowData => {
    return (
     <div>
        <div className='row'>
            <div className='col-sm-4'>
                <h5>Details</h5><br/>
                300032
                Lecturer
                Civil and Environmental Technology
                Terminate Date - 2025-02-14
                Faculty of Technology
            </div>
            <div className='col-sm-8'>
                

            </div>

        </div>
      </div>
    );
  };

  return (
     <div class="product__discount">
        <div class="section-title product__discount__title">
            <h2>Create Email Accounts</h2>
            <div style={{ padding:20 }}></div>
            <DataTableComponent columns={columns} data={rowData} />
        </div>               
    </div>
  )
}
