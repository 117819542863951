import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import useAuth from '../../hooks/useAuth';

const client_id = "9caf3655-a29d-461f-9c52-6b5845238b4f";
const client_secret = "CelpF3oLc57066kyoG51hw7yRl7Til1tp9UBkvyX";
const redirect_uri = "https://usjnet.sjp.ac.lk/user_callback";


const UserCallbackSSO = () => {
  const navigate = useNavigate();
const { auth, setAuth } = useAuth();
const [accCode,setAccCode] = useState();

useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const storedState = localStorage.getItem("state");
    const cookies = new Cookies();

cookies.set('accessToken', code, { path: '/' });
cookies.set('privilages', ["logged"], { path: '/' });
cookies.set('userStatus', "logged", { path: '/' });
const accessToken = code;
const privilages = ["logged"];
const userStatus = "logged";
//setAuth({accessToken,privilages,userStatus});
//localStorage.setItem('authToken', code);
//navigate('/home');
window.location.href = `https://usjnet.sjp.ac.lk/home`;

 
//     console.log(storedState,state);
//     if (state !== storedState) {
//       console.error('State mismatch');
//       return;
//     }
// console.log("call back start");
//     const submitData = {
//       grant_type: 'authorization_code',
//       code,
//       redirect_uri,
//       client_id,
//       client_secret
//     };

//     console.log(submitData);
//     const url = new URL(window.location.href);
//     const params = new URLSearchParams(url.search);
//     const codeParam = params.get('code');

//     console.log(codeParam)

//     // Create a new FormData instance
// const formData = new FormData();

// // Append your data to the FormData instance
// for (const [key, value] of Object.entries(submitData)) {
//   formData.append(key, value);
// }

// //console.log(submitData,"submitted data");
//     // axios.post("https://usjnet.sjp.ac.lk/sso/oauth/token",formData)
//     //   .then(async function (response) {
//     //       console.log("recive response");
//     //      console.log(response?.data);
//     //     if(response.status === 200){
//     //       console.log("success");
//     //       console.log(response?.data);
//     //       if(response?.status === 200){
//     //         console.log(response?.data);
//     //       }else{
//     //         console.log("fail get access token");
//     //       }
//     //     }
//     //   })
//     //   .catch(function (error) {
        
//     //     setTimeout(() => {
//     //       console.log(error);
//     //     }, 2000);
//     //   });

//     // fetch('https://usjnet.sjp.ac.lk/sso/oauth/token', {
//     //   method: 'POST',
//     //   mode: "no-cors", 
//     //   headers: {
//     //     'Content-Type': 'application/x-www-form-urlencoded',
//     //   },
//     //   body: new URLSearchParams(formData).toString(),
//     // })
//     //   .then(response => response)
//     //   .then(data => {
//     //     console.log("data resive done");
//     //     console.log(data);
//     //     // console.log("gettig",data.access_token);
//     //     // if (data.access_token) {
//     //     //   localStorage.setItem("access_token", data.access_token);
//     //     //   navigate('/user_connect');
//     //     // } else {
//     //     //   console.error('Token exchange failed', data);
//     //     // }
//     //   })
//     //   .catch(error => {
//     //     console.error('Error:', error);
//     //   });

//   const getToken =async () => {
// const response = await fetch('https://usjnet.sjp.ac.lk/sso/oauth/token', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded',
//             },
//             body: new URLSearchParams({
//                 'grant_type': 'authorization_code',
//                 'client_id': client_id,
//                 'client_secret': client_secret,
//                 'redirect_uri':redirect_uri,
//                 'code' : code,
//             })
//         });
//         if (response.ok) {
//           console.log("authenticationi success");
//             const data = await response.json();
//             localStorage.setItem('authToken', data.access_token);
//             // Handle successful login, e.g., redirect to another page
//         } else {
//             const errorData = await response.json();
//             console.log(errorData.error_description)
//         }
//   }
    
// getToken();
  
  }, [navigate]);

  return <div>Loading...</div>
};

export default UserCallbackSSO;
