// Import React and styled-components
import React from 'react';
import styled from 'styled-components';
import NavbarComponent from '../components/navbar-component/navbar-component';
import EduromImage from '../assets/eduroambn.jpg'
import { Breadcrumb } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import FooterComponent from '../components/common/footer-component/footer-component';

// Styled Components
const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;

`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

const Logo = styled.img`
  width: 70%;
  margin-right: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const Subtitle = styled.h2`
  font-size: 20px;
  color: #0066cc;
  font-weight: normal;
  margin-top: 0;
`;

const Description = styled.p`
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const Highlight = styled.span`
  color: #990000;
  font-weight: bold;
`;

const SectionTitle = styled.h2`
  font-size: 22px;
  color: #333;
  margin-top: 20px;
`;

const ImportantText = styled.span`
  color: #990000;
  font-weight: bold;
`;

const Box = styled.div`
 // background-color: #ffeb3b;
  padding: 10px;
  text-align: left;
  font-size: 20px;
  color: #990000;
  font-weight: bold;
  margin: 20px 0 20px 100px;
`;

const FacultyTable = styled.div`
 // background-color: #ffeb3b;
  padding: 10px;
  margin: 0 0 20px 30px;
  width: 700px;

`;

const FacultyRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 18px;
  color: #990000;
  border-bottom: 1px solid #990000;

  &:last-child {
    border-bottom: none;
  }
`;

const FacultyName = styled.span`
  font-weight: bold;
`;

const LinkSection = styled.div`
  margin-top: 20px;
`;

const Link = styled.a`
  color: #0066cc;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

// Component
const EduroamPage = () => {
  const navigate = useNavigate();
  return (
<>
<NavbarComponent />
<div class="container">
      <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} >
            Edurom
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
    </div>
    <Container>
      <Header>
        <Logo src={EduromImage} alt="eduroam logo" />
        {/* <div>
          <Title>eduroam</Title>
          <Subtitle>Get Connected, Wherever You Are</Subtitle>
        </div> */}
      </Header>
      
      <SectionTitle>What is eduroam?</SectionTitle>
      <Description>
        <Highlight>eduroam</Highlight> (education roaming) is an international roaming service for users in research, higher education and further education. It provides researchers, teachers, and students network access when visiting an institution other than their own.
        Authentication of users is performed by their home institution, using the same credentials as when they access the network locally, while authorization to access the Internet and possibly other resources is handled by the visited institution.
        Users do not have to pay for using eduroam.
      </Description>
      <Description>
        In Sri Lanka and some other countries, Internet access via eduroam is available at locations other than the participating institutions, e.g. in libraries, public buildings, railway stations, city centres and airports.
      </Description>

      {/* Additional Content for Staff */}
      <SectionTitle>Who can use eduroam?</SectionTitle>
      <Description>
        All the staff members and the undergraduates of the University who have obtained a <ImportantText>USJNet ID</ImportantText> and a <ImportantText>Password</ImportantText> can use eduroam.
      </Description>
     

      <SectionTitle>I am a staff member. How do I connect?</SectionTitle>
      <Description>
        Wherever you see ‘eduroam’ appear in your list of Wi-Fi networks, select ‘eduroam’.
      </Description>
      <Description>
        Enter your <ImportantText>USJNet ID</ImportantText> along with the domain <ImportantText>@sjp.ac.lk</ImportantText> for Username, as shown below.
      </Description>

      <Box>USJNet ID@sjp.ac.lk</Box>

      <Description>
        Then, enter the <ImportantText>USJNet Password</ImportantText>. (“<ImportantText>Trust</ImportantText>” the connection on Apple Devices)
      </Description>
      <Description>
        Once you have connected for the first time, you never need to enter your details again. eduroam will automatically connect you whenever you are in range of an eduroam hotspot.
      </Description>

      {/* Additional Content for Students */}
      <SectionTitle>I am a student. How do I connect?</SectionTitle>
      <Description>
        Wherever you see ‘eduroam’ appear in your list of Wi-Fi networks, select ‘eduroam’.
      </Description>
      <Description>
        Enter your <ImportantText>USJNet ID</ImportantText> along with the respective <ImportantText>faculty domain</ImportantText> for Username, as shown below.
      </Description>

      <FacultyTable>
        <FacultyRow>
          <FacultyName>Humanities & Social Sciences</FacultyName>
          <span>ar?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
          <FacultyName>Applied Sciences</FacultyName>
          <span>as?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
          <FacultyName>Management Studies & Commerce</FacultyName>
          <span>mc?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
          <FacultyName>Medical Sciences</FacultyName>
          <span>me?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
          <FacultyName>Technology</FacultyName>
          <span>te?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
          <FacultyName>Engineering</FacultyName>
          <span>en?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
          <FacultyName>Allied Health Sciences</FacultyName>
          <span>hs?????@sjp.ac.lk</span>
        </FacultyRow>
         <FacultyRow>
          <FacultyName>Dental Sciences</FacultyName>
          <span>de?????@sjp.ac.lk</span>
        </FacultyRow>
         <FacultyRow>
          <FacultyName>Urban and Aquatic Bioresources</FacultyName>
          <span>br?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
          <FacultyName>Computing</FacultyName>
          <span>fc?????@sjp.ac.lk</span>
        </FacultyRow>
        <FacultyRow>
         
        </FacultyRow>
      </FacultyTable>

      <Description>
        Then, enter the <ImportantText>USJNet Password</ImportantText>. (“<ImportantText>Trust</ImportantText>” the connection on Apple Devices)
      </Description>
      <Description>
        Once you have connected for the first time, you never need to enter your details again. eduroam will automatically connect you whenever you are in range of an eduroam hotspot.
      </Description>

      {/* Important Links */}
      <SectionTitle>Important links</SectionTitle>
     
      <LinkSection>
        <Link href="https://eduroam.ac.lk/" target="_blank">https://eduroam.ac.lk/</Link><br />
        <Link href="https://www.eduroam.org/" target="_blank">https://www.eduroam.org/</Link>
      </LinkSection>
    </Container>
    <FooterComponent />
</>

     
  );
};

export default EduroamPage;
