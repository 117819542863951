import React, { useState } from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import styled from 'styled-components';
import { Breadcrumb } from 'rsuite';
import { Link, useNavigate } from 'react-router-dom';
import FooterComponent from '../components/common/footer-component/footer-component';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { BaseUrl } from '../api/axios';
import { Configurations } from '../config';

export default function DownloadPage() {

    const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
`;
const navigate = useNavigate();


const downloadLinks = [
    {
      title: "Nomination Form – University Provident Fund",
      //description: "A clean and modern resume template for professionals.",
      url: Configurations.baseUrl+"/assets/usjnet_doc/Downloads/UPF-Nomination-Form.pdf",
    },
    {
      title: "Information for Recruitment of Contract and Temporary Employees",
      //description: "A comprehensive proposal template for project planning.",
      url: Configurations.baseUrl+"/assets/usjnet_doc/Downloads/Information-for-Recruitment-of-Contract-and-Temporary-Employees.pdf",
    },
    {
      title: "Check List - Application for the Post of Professor",
      //description: "An example business report format for corporate use.",
      url: Configurations.baseUrl+"/assets/usjnet_doc/Downloads/Document-Check-List-for-the-Professor-Application.docx",
    }
  ];

  return (
   <>
   <NavbarComponent />
   <div class="container">
      <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} >
            Downloads
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
    </div>

   <Container>
        
      <Title>Downloads</Title>


    <Box sx={{ textAlign: 'center', padding: 4 }}>
    
      <Grid container spacing={4} justifyContent="center">
        {downloadLinks.map((link, index) => (
          <Grid item style={{ width:'100%',textAlign:'left' }}  key={index}>
            <Card >
              <CardContent>
                <Typography 
                component="div">
                  <span style={{ fontFamily: 'Arial, sans-serif',fontSize:18,fontWeight:500 }}>{link.title}</span>
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                 
                </Typography>
                <Button
                  variant="contained"

                  style={{ backgroundColor:'white',color:'#990000'   }}
                  href={link.url}
                  download
                  startIcon={<DownloadIcon />}
                  fullWidth
                  target='_blank'
                     
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>


      <div style={{ padding:200 }}></div>
    </Container>
    <FooterComponent />
   </>
  )
}
