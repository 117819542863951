import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Input,
    Typography,
    useMediaQuery,
    Grid
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IOSSwitchComponent from '../switch/iso-style-switch';
import { DisabledByDefault } from '@mui/icons-material';
import useServices from '../../hooks/useServices';

const CommonModel = ({reLogAccount, type="staff",openModel,handleSubmitUserUpdate,modelViewData=[],setIsModelOpen }) => {
    const [open, setOpen] = useState(false);
     const [userDetails, setUserDetails] = useState([]);
       // State to manage editable fields
    const [isEditing, setIsEditing] = useState([]);
    const [services] = useServices()


    

    useEffect(() => {
        setOpen(openModel);
       // console.log(services);
    }, [openModel]);

    useEffect(()=>{
        console.log(modelViewData)
        setUserDetails(modelViewData);
        setIsEditing({
        email: false,
        mobileNo: false,
        zoomStatus: false,
        accountStatus:false,
        })
    },[modelViewData])

    // User details state
   

  

    const handleClose = () => {
        setOpen(false);
        setIsModelOpen(false);
    };

     const handleSubmit = (status) => {
       // 
       
      const submittedData = {
          old_sjp_email: userDetails.old_sjp_email,
          new_sjp_email: userDetails.email,
          mobile_no: userDetails.mobileNo,
          zoom_state: userDetails.zoomStatus,
          empNo: userDetails.empNo,
          accountStatus: userDetails.accountStatus,
          status: status,
      };
      handleSubmitUserUpdate(submittedData);
    //   setOpen(false);
    //   setIsModelOpen(false);
    };

    // Handle input change
    const handleChange = (e) => {
      console.log(e)
        const { name, value } = e.target;
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

     const handleChangeSwitch = (value,valueId) => {
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [valueId]: value,
        }));
    };

    // Toggle edit mode
    const toggleEdit = (field) => {
        setIsEditing((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is small

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth={isMobile ? 'xs' : 'sm'} fullWidth>
                <DialogTitle>User Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={isMobile ? 1 : 3} sx={{ padding: isMobile ? 1 : 3 }}>
                       

                        <Grid item xs={12}>
                              {/* User Email */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
                                      Name:
                                  </Typography>
                                  
                                  {/* Input and Icon */}
                                  <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                      <Input 
                                          disabled={true}
                                          value={userDetails.name}
                                          sx={{ flexGrow: 1, mr: 1 }}  // Flex-grow allows the input to take up remaining space, margin-right for spacing
                                      />
                                      
                                  </Box>
                              </Box>
                              <Divider />
                          </Grid>

                          <Grid item xs={12}>
                              {/* User Email */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
                                      EMP No:
                                  </Typography>
                                  
                                  {/* Input and Icon */}
                                  <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                      <Input 
                                          disabled={true}
                                          value={userDetails.empNo}
                                          sx={{ flexGrow: 1, mr: 1 }}  // Flex-grow allows the input to take up remaining space, margin-right for spacing
                                      />
                                      
                                  </Box>
                              </Box>
                              <Divider />
                          </Grid>

                        {type === "staff" && (
                            <Grid item xs={12}>
                              {/* User Email */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
                                      Email:
                                  </Typography>
                                  
                                  {/* Input and Icon */}
                                  <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                      <Input 
                                          disabled={!isEditing.email}
                                          value={userDetails.email}
                                          name="email"
                                          onChange={handleChange}
                                          sx={{ flexGrow: 1, mr: 1 }}  // Flex-grow allows the input to take up remaining space, margin-right for spacing
                                      />
                                      <IconButton size="small" color="primary" onClick={() => toggleEdit('email')}>
                                        {isEditing.email ? 
                                        <>
                                        <Button variant='contained' onClick={()=>handleSubmit("email")}>
                                            Update
                                        </Button><DisabledByDefault style={{ color:'red' }} fontSize="small" />
                                        </> : <EditOutlinedIcon fontSize="small" />}
                                         
                                      </IconButton>
                                  </Box>
                              </Box>
                              <Divider />
                          </Grid>
                        )}

                        


                        <Grid item xs={12}>
                              {/* User Email */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
                                      Mobile No:
                                  </Typography>
                                  
                                  {/* Input and Icon */}
                                  <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                      <Input 
                                          disabled={!isEditing.mobileNo}
                                          value={userDetails.mobileNo}
                                          name="mobileNo"
                                          onChange={handleChange}
                                          sx={{ flexGrow: 1, mr: 1 }}  // Flex-grow allows the input to take up remaining space, margin-right for spacing
                                      />
                                      <IconButton size="small" color="primary" onClick={() => toggleEdit('mobileNo')}>
                                           {isEditing.mobileNo ? 
                                           <>
                                           <Button variant='contained' onClick={()=>handleSubmit("mobileNo")}>
                                            Update
                                        </Button><DisabledByDefault style={{ color:'red' }} fontSize="small" />
                                           </>: <EditOutlinedIcon fontSize="small" />}
                                      </IconButton>
                                  </Box>
                              </Box>
                              <Divider />
                          </Grid>

                        {type === "staff" && services?.userInitialData?.userRole === 2 && (
                            <Grid item xs={12}>
                              {/* User Email */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
                                     Zoom Status:
                                  </Typography>
                                  
                                  {/* Input and Icon */}
                                  <Box sx={{ display: 'flex', alignItems: 'left', flexGrow: 1 }}>
                                      <IOSSwitchComponent switchName="zoomStatus" checkedWtitch={userDetails.zoomStatus} handleChangeSwitch={handleChangeSwitch} isEditing={!isEditing.zoomStatus}/>
                                      <IconButton size="small" color="primary" onClick={() => toggleEdit('zoomStatus')}>
                                           {isEditing.zoomStatus ? 
                                           <>
                                           <Button variant='contained' onClick={()=>handleSubmit("zoom")}>
                                            Update
                                        </Button><DisabledByDefault style={{ color:'red' }} fontSize="small" />
                                           
                                           </>: <EditOutlinedIcon fontSize="small" />}
                                      </IconButton>
                                  </Box>
                              </Box>
                              <Divider />
                          </Grid>

                        )}

                          

                    {type === "staff" && services?.userInitialData?.userRole === 2 && (
                        <Grid item xs={12}>
                              {/* User Email */}
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Typography variant="subtitle1" fontWeight="bold" sx={{ minWidth: 120 }}>
                                     Account Status:
                                  </Typography>
                                  
                                  {/* Input and Icon */}
                                  <Box sx={{ display: 'flex', alignItems: 'left', flexGrow: 1 }}>
                                      <IOSSwitchComponent switchName="accountStatus" checkedWtitch={userDetails.accountStatus} handleChangeSwitch={handleChangeSwitch} isEditing={!isEditing.accountStatus}/>
                                      <IconButton size="small" color="primary" onClick={() => toggleEdit('accountStatus')}>
                                           {isEditing.accountStatus ? 
                                           <>
                                           <Button variant='contained' onClick={()=>handleSubmit("accState")}>
                                            Update
                                        </Button><DisabledByDefault style={{ color:'red' }} fontSize="small" />
                                           
                                           </>: <EditOutlinedIcon fontSize="small" />}
                                      </IconButton>
                                  </Box>
                              </Box>
                              <Divider />
                          </Grid>
                    )}
                          

                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    {(services?.userInitialData?.empNo === "12394" || services?.userInitialData?.empNo === "12393" || services?.userInitialData?.empNo === "1914") && (
                       <Button onClick={()=>reLogAccount(userDetails.email)}>
                        User Account
                        </Button>
                    )}

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CommonModel;
