import React from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import styled from 'styled-components';
import IframeComponent from '../components/dynamic-iframe-component/common-iframe';
import { Breadcrumb } from 'rsuite';
import { Link, useNavigate } from 'react-router-dom';
import FooterComponent from '../components/common/footer-component/footer-component';

export default function WiFiCoveragePage() {

    const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
`;
const navigate = useNavigate();

  return (
   <>
   <NavbarComponent />

   <div class="container">
      <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} >
            Wi-Fi Coverage
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
    </div>

   <Container>
        
      <Title>Wi-Fi Coverage</Title>
      <IframeComponent iframeSrc={`https://www.google.com/maps/d/u/0/embed?mid=1yzAZfxugQClIF8hrJLxJRo3dJp4&ehbc=2E312F`}/>

    </Container>
    <FooterComponent />
   </>
  )
}
