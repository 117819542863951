import React, { Fragment, useEffect } from 'react'
import LoginPageComponent from '../components/login-page-component/login-page-component'
import backgroundImage from '../assets/temp_usjnet_login.PNG'
import SkeletonTypography from '../components/common/skelton/skelton-one';
import SkeletonChildren from '../components/common/skelton/skeleton-two';
import NavbarComponent from '../components/navbar-component/navbar-component';

export default function LoginPage() {

  const divStyle = {
    backgroundImage: `url(${backgroundImage})`, // Use the imported image
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  useEffect(()=>{
    window.location.href = `https://usjnetsso.sjp.ac.lk/login/user_login_sso`;
  },[])

  return (
   <Fragment>
    <NavbarComponent />
        <section class="hero ">
        <div class="container">
            <div class="row"> 
                <SkeletonTypography />                 
            </div>
                
            <div className="col-lg-4 mt-4">
               <SkeletonChildren />
            </div>
        </div>
    </section>
    </Fragment>
  )
}
