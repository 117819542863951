import { Image, Padding } from '@mui/icons-material'
import React, { Fragment } from 'react'
import { BaseUrl } from '../../../api/axios';
import LogoImage from "../../../assets/footer_logo_usjnet.png";
import { Box, Grid2, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Address from "../../../assets/cits-address_2.png";
import { red, yellow } from '@mui/material/colors';

export default function FooterComponent() {
    const currentYear = new Date().getFullYear();
  return (
    <Fragment>
        <div style={{ padding:50 }}></div>
        <footer class="" style={{ backgroundColor:"#990000",padding:20 }}>
        <div class="container" >
            <div class="row">
                <div class="col-lg-5">
                    <div class="">
                        {/* <div class="footer__about__logo">
                            <a href={`${BaseUrl}/home`}><img src={LogoImage} alt="logo" style={{ marginRight: "20px" }} height={40} /></a>
                        </div>
                        <ul>
                            <li>Address: 60-49 Road 11378 New York</li>
                            <li>Phone: +65 11.188.888</li>
                            <li>Email: hello@colorlib.com</li>
                        </ul> */}

                        <div style={{ display:'flex',flexDirection:'row',marginTop:20 }}>
                            <div > <img src={LogoImage} alt="CITS" style={{ width: '100%', height: 'auto' }} /></div>

                            {/* <div> <img src={Address} alt="CITS" style={{ width: '100%', height: 'auto' }} /></div> */}
                             <div style={{ color:'white',borderLeft: '1px solid white',margin:0,padding:0,fontSize:12,fontFamily:'Tahoma, Geneva, sans-serif' }}> <div style={{ marginLeft:10 }}>Centre for IT Services</div><div style={{ marginLeft:10,marginTop:2 }}>University of Sri Jayewardenepura</div><div style={{ marginLeft:10,marginTop:2 }}>Nugegoda, Sri Lanka.</div><div style={{ marginLeft:10,marginTop:2 }}>Phone: +94 11 2758 757</div><div style={{ marginLeft:10,marginTop:2 }}>Email: <a style={{ color:'yellow',textDecoration: 'none' }}>cits@sjp.ac.lk</a></div><div style={{ marginLeft:10,marginTop:2 }}>Web: <a style={{ color:'yellow',textDecoration: 'none' }} href='https://cits.sjp.ac.lk/' target='_blank'>https://cits.sjp.ac.lk</a></div></div>
                        </div>
 
                    </div>
                </div>
                 <div class="col-lg-7" >
                    <div style={{ display:'flex',justifyContent:'flex-end' }}>
                            <div class="" style={{ marginTop:110 }}>
                                <div className="footer__copyright__text">
                                    <p style={{ color: 'white', fontSize: 12, fontFamily: 'Tahoma, Geneva, sans-serif' }}>
                                    Copyright &copy; {currentYear} CITS | All Rights Reserved  (Version 3.0)
                                    </p>
                                </div>
                            
                            </div>
                        
                    </div>
                </div> 
               
            </div>
            {/* <div class="row">
                <div class="col-lg-12">
                    <div class="footer__copyright">
                        <div class="footer__copyright__text"><p style={{ color:'white' }}>
  Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | CITS
  </p></div>
                      
                    </div>
                </div>
            </div> */}
        </div>
    </footer>
    </Fragment>
  )
}
