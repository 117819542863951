import React from 'react';
import styled from 'styled-components';
import NavbarComponent from '../components/navbar-component/navbar-component';
import EResourceCard from '../components/home-page-component/card-list-component-3';
import { Breadcrumb } from 'rsuite';
import { Link, useNavigate } from 'react-router-dom';
import FooterComponent from '../components/common/footer-component/footer-component';

// Styled components for the layout and buttons
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const InstructionText = styled.p`
  text-align: center;
  margin-bottom: 10px;
`;

const LinkText = styled.a`
  color: red;
  font-weight: bold;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const DatabaseButton = styled.button`
  padding: 10px 20px;
  border: 2px solid #007BFF;
  border-radius: 5px;
  background-color: white;
  color: #007BFF;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 150px;
  
  &:hover {
    background-color: #007BFF;
    color: white;
  }
`;

const FooterText = styled.p`
  margin-top: 20px;
  font-size: 14px;
`;

const ContactInfo = styled.p`
  font-size: 14px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 15px;
  font-family: Arial, sans-serif;
  text-align: center;
`;

const EResourcePage = () => {
  const navigate = useNavigate();
  return (
    <>
    <NavbarComponent />
    <div class="container">
      <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item >
            E-Resources
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
    </div>
    <div class="container">

       <Title>Off-Campus Access to Electronic Databases</Title>

      <EResourceCard />
      <div style={{ padding:20 }}></div>
      <Paragraph>Use the USJNet ID and Password to access the databases</Paragraph>

      {/* <InstructionText>
        Please use your <strong>USJNet ID</strong> and the <strong>Password</strong> to remote access the below databases.
        <LinkText href="#"> CLICK HERE </LinkText> or use the link given at the bottom of the page to obtain a <strong>USJNet ID</strong> and a <strong>Password</strong>.
      </InstructionText>
      <ButtonContainer>
        <DatabaseButton>Emerald</DatabaseButton>
        <DatabaseButton>Jstor</DatabaseButton>
        <DatabaseButton>Oxford Journals</DatabaseButton>
        <DatabaseButton>Taylor & Francis</DatabaseButton>
        <DatabaseButton>Scopus</DatabaseButton>
      </ButtonContainer>
      <FooterText>
        Obtain a <strong>USJNet ID</strong> and a <strong>Password</strong> from <LinkText href="#">USJNet Sphere</LinkText>
      </FooterText>
      <ContactInfo>
        For any queries please contact:<br />
        1. Ms. J.G. Chandani – Senior Assistant Librarian (IT Division) – 0112758521
      </ContactInfo> */}
    </div>
    <div style={{ padding:200 }}></div>
    <FooterComponent />
    </>
     
  );
};

export default EResourcePage;
