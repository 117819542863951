import React, { Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Avatar, Whisper, Popover, Button } from 'rsuite';
import LogOutImage from "../../assets/logout.png";
import LogoImage from "../../assets/logo.png";
import { Link } from 'react-router-dom';
import useServices from '../../hooks/useServices';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import { Configurations } from '../../config';



const DefaultPopover = React.forwardRef(({ content, ...props }, ref) => {
  return (
    <Popover ref={ref}  {...props}>
      <span>Profile</span>
    </Popover>
  );
});

const StyledAvatar = styled(Avatar)`
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  overflow: hidden;
  border-radius: 50%; /* Makes the avatar circular */
  background-color: #f0f0f0; /* Optional: background color for better visibility */

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const CustomComponent = ({ placement }) => (
  // <Whisper
  //   trigger="click"
  //   placement={placement}
  //   controlId={`control-id-${placement}`}
  //   speaker={
  //       <DefaultPopover content={`I am positioned to the ${placement}`} />
  //   }
  // >
  //   <Avatar style={{ marginRight:20 }} color="white" bordered circle src="https://i.pravatar.cc/150?u=1" />
  // </Whisper>
            <Whisper
                placement="bottom"
                trigger="hover"
                controlId="control-id-hover-enterable"
                speaker={profilePopover}
                enterable
              >
                <Link href="#">
                 <StyledAvatar style={{ marginRight:20 }} color="white" bordered circle src="https://i.pravatar.cc/150?u=1" />
                </Link>
              
              </Whisper>
);

const CustomPopover = () => (
  <Popover>
    <Popover.Content>
      Sign Out
    </Popover.Content>
  </Popover>
);


const speaker = (
  <Popover >
    <p style={{ fontSize:12 }}>Sign Out</p>
  </Popover>
);

const profilePopover = (
  <Popover >
    <p style={{ fontSize:12 }}>Change My Password</p>
  </Popover>
);

export default function NavbarComponent() {

  const services = useServices();
  const cookies = new Cookies();

  const logout = () => {
    
    
    cookies.remove('accessToken', { path: '/' });
    cookies.remove('privilages', { path: '/' });
    cookies.remove('userStatus', { path: '/' });
    window.location.href = `${Configurations.baseUrl}/user_logout`;
  }


  return (
    <Fragment>
      <Navbar key='sm' expand='sm' className="mb-3" style={{ backgroundColor: "#990000" }}>
        <Container>
          <Navbar.Brand style={{ color: 'white' }} href="/  ">
            <img src={LogoImage} alt="logo" style={{ marginRight: "20px" }} height={40} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                <img src={LogOutImage} alt="logo" width={30} height={30} style={{ filter: 'invert(1)' }} /> USJNet
              </Offcanvas.Title>
            </Offcanvas.Header>


            {cookies.get('accessToken') !== 'undefined' && (
 <Offcanvas.Body>
              <Nav className="d-flex justify-content-end align-items-center flex-grow-1 pe-3">
                <div className="d-flex align-items-center">
                  {/* <CustomComponent placement="bottomEnd" /> */}

                  <div style={{ color:'white',marginRight:20,fontWeight:800 }}>{services[0].userInitialData.userName}</div>
                

                   <Whisper
                placement="bottom"
                trigger="hover"
                controlId="control-id-hover-enterable"
                speaker={profilePopover}
                enterable
              >
                <Nav.Link href="/change-account-password" style={{ padding:0,margin:0 }}>
                 <StyledAvatar style={{ marginRight:20,marginTop:4 }} color="white" bordered circle src={services[0].userInitialData.profileImage} />
                 </Nav.Link>
              
              </Whisper>


              <Whisper
                placement="bottom"
                trigger="hover"
                controlId="control-id-hover-enterable"
                speaker={speaker}
                enterable
              >
                <Nav.Link className="ms-2" href="#" onClick={logout}>
                    <img src={LogOutImage} alt="logout" width={25} height={25}  />
                  </Nav.Link>
              </Whisper>

                  
                </div>
              </Nav>
              {/* Uncomment and use if you need a search form
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-success">Search</Button>
              </Form> */}
            </Offcanvas.Body>
            )}

           
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </Fragment>
  )
}
