import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import EduromImage from '../../assets/Eduroam-900x400.jpg';
import { useNavigate } from 'react-router-dom';
import SkeletonChildren from '../common/skelton/skeleton-two';
import USJNetWiFi from '../../assets/USJNet-WiFi.jpg'
import USJNetCoverage from '../../assets/wificoverage.jpg'

export default function ActionAreaCard({isLoading = true}) {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div style={{ padding: 40 }}></div>

      {/* Container for inline cards */}
      <div style={{
        display: 'flex', 
        justifyContent: 'space-around', 
        flexWrap: 'wrap', 
        gap: '20px'
      }}>
        {/* Card 1 */}
        <Card sx={{ maxWidth: 345,minWidth:345 }} >
          <CardActionArea onClick={()=>navigate("/wifiPage")}>
            {isLoading ? (
                <SkeletonChildren />
            ):(
              <>
                <CardMedia
                component="img"
                height="140"
                image={USJNetWiFi}
                alt="Eduroam"
              />
              <CardContent style={{ maxHeight:50 }}>
                <Typography gutterBottom variant="h6" component="div">
                  USJNet Wi-Fi
                </Typography>
                {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Lizards are a widespread group of squamate reptiles, with over 6,000
                  species, ranging across all continents except Antarctica
                </Typography> */}
              </CardContent>
              
              </>
              
            )}
             
            
          </CardActionArea>
        </Card>

   
        <Card sx={{ maxWidth: 345,minWidth:345 }}>
          <CardActionArea onClick={()=>navigate("/EduroamPage")}>
            {isLoading ? (
              <SkeletonChildren />
            ):(
              <>
              <CardMedia
              component="img"
              height="140"
              image={EduromImage}
              alt="Eduroam"
            />
            <CardContent style={{ maxHeight:50 }}>
              <Typography gutterBottom variant="h6" component="div">
                Eduroam
              </Typography>
             
            </CardContent>
              </>
            )}
             
            
          </CardActionArea>
        </Card>

        {/* Card 3 */}
        <Card sx={{ maxWidth: 345,minWidth:345 }}>
          <CardActionArea onClick={()=>navigate("/wi-fi-coverage")}>
              {isLoading ? (
                <SkeletonChildren />
            ):(
                <>
                <CardMedia
              component="img"
              height="140"
              image={USJNetCoverage}
              alt="Eduroam"
            />
             <CardContent style={{ maxHeight:50 }}>
              <Typography gutterBottom variant="h6" component="div">
                Wi-Fi Coverage
              </Typography>
             
            </CardContent>
                
                </>
            )}

             
            
          </CardActionArea>
        </Card>
      </div>
    </div>
  );
}
