import React, { Fragment } from 'react'
import { Button, Message, RadioTile, RadioTileGroup} from 'rsuite'
import { Col, Form, Row } from 'react-bootstrap'
import UserDetailsPanel from './user-details-panel';
import { useNavigate } from 'react-router-dom';

export default function ChangeSuccessForm({handleChange,userDetails}) {

    const onSubmitAction = () => {
        handleChange('stepCount',3);
    }

    const navigation = useNavigate();

    const goBackLogin = () => {
        navigation("/");
    }

  return (
   <Fragment>
       <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                            <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                               
                               <Message type="success" centered showIcon header="Password change successfully completed!">
                                    <p>
                                    You have changed the password successfully. Sign in to enjoy the services at USJNet Sphere.
                                    </p>
                                    <p>
                                        <Button onClick={goBackLogin} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Sign In</Button>
                                    </p>
                                </Message>
                                  
                            </Col>
                        </Row>
                    </Row>
    </Fragment>
  )
}
