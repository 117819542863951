
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const client_id = "9caf3655-a29d-461f-9c52-6b5845238b4f";
const redirect_uri = "https://usjnet.sjp.ac.lk/user_callback";
const scope = "view-user";
const response_type = "code";

const UserLoginSSO = () => {
  const state = uuidv4();
  localStorage.setItem("state", state);

  const query = new URLSearchParams({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    prompt: true
  }).toString();

  window.location.href = `https://usjnetsso.sjp.ac.lk/sso/oauth/authorize?${query}`;
};

export default UserLoginSSO;
