import React, { useEffect, useState } from 'react'
import DataTableComponent from '../../common/data-table-component/data-table-component'
import axios from '../../../api/axios';
import { Button, IconButton, Input, TextField } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CommonModel from '../../Models/common-model';
import Cookies from 'universal-cookie';
import { Configurations } from '../../../config';

export default function USJNetStudentMembersComponent() {


  const [rowData, setRowData] = useState([]);
  const [rowDataTemp, setRowDataTemp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
const [emailCreateData,setEmailCreateData] = useState([]);
const [emailCreateDataTemp,setEmailCreateDataTemp] = useState([]);
const [modelViewData, setModelViewData] = useState([]);
const [isModelOpen,setIsModelOpen] = useState(false);
const [searchText, setSearchText] = useState("");

// useEffect(()=>{

//   setIsLoading(true);
//   axios.post("/usjnet-student-member-details",{reg_no:})
//       .then(async function (response) {
//         if(response.status === 200){
//             setEmailCreateData(response.data);
//             setEmailCreateDataTemp(response.data);
//             setIsLoading(false);
//         }
//       })
//       .catch(function (error) {
      
//         setTimeout(() => {
//           console.log(error);
//         }, 2000);
//       });
// },[])

const reLogAccount = (email) => {
  setIsLoading(true);
  axios.post("/user/re-log",{email:email})
      .then(async function (response) {
        if(response.status === 200){
          const cookies = new Cookies();
            // cookies.remove('accessToken', { path: '/' });
            // cookies.remove('privilages', { path: '/' });
            // cookies.remove('userStatus', { path: '/' });
            // cookies.set('accessToken', response.data?.access_token, { path: '/', domain: 'usjnet.sjp.ac.lk' });
            // cookies.set('privilages', ["logged"], { path: '/', domain: 'usjnet.sjp.ac.lk' });
            // cookies.set('userStatus', "logged", { path: '/', domain: 'usjnet.sjp.ac.lk' });
            cookies.set('accessToken', response.data?.access_token, { path: '/' });
            cookies.set('privilages', ["logged"], { path: '/' });
            cookies.set('userStatus', "logged", { path: '/' });
            
           


             //window.location.href = `https://usjnet.sjp.ac.lk/home`;
            // setIsLoading(false);
            const newWindow = window.open('https://usjnet.sjp.ac.lk/home?window=new', '_blank', 'width=800,height=600,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no');

              if (newWindow) {
                // Set cookies in the new window with a different path to isolate them
                const newWindowCookies = new Cookies(newWindow.document.cookie);
                newWindowCookies.set('accessToken', response.data?.access_token, { path: '/newwindow', domain: 'usjnet.sjp.ac.lk' });
                newWindowCookies.set('privilages', ["logged"], { path: '/newwindow', domain: 'usjnet.sjp.ac.lk' });
                newWindowCookies.set('userStatus', "logged", { path: '/newwindow', domain: 'usjnet.sjp.ac.lk' });

                // Optionally, pass messages between the windows (if needed)
                newWindow.postMessage({ success: true }, '*');
              }
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
}

const handleSubmitUserUpdate = (submitData) => {
  setIsLoading(true);
  //console.log(submitData);
  axios.post("/admin/student-update",submitData)
      .then(async function (response) {
        if(response.status === 200){
          console.log(response.data);
            alert("updated");
            setIsLoading(false);
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
}

    const columns = [
    { id: 'id', label: 'ID', minWidth: 10 },
    { id: 'empNo', label: 'Emp No', minWidth: 80 },
    { id: 'name', label: 'Name', minWidth: 200 },
    { id: 'mobileNo', label: 'Mobile No', minWidth: 170 },
    { id: 'sjpEmail', label: 'SJP Email', minWidth: 170 },
    {
        id: 'action',
        label: 'Action',
        minWidth: 170,
        align: 'center',
        
    },
    
  ];

    useEffect(()=>{
    //console.log(emailCreateData);
      var res = emailCreateData.map((val,index)=>({
          id : index+1,
          name: val?.personal_details?.NameInitials,
          empNo: val?.emp_no,
          mobileNo:val?.personal_details?.StuTeleMobile,
          sjpEmail: val?.personal_details?.NIC,
          action:<>
             <IconButton onClick={() => changeModelViewData(val)}>
                <SettingsOutlinedIcon />
              </IconButton>
          </>,
      }));
    setRowData(res);
    setRowDataTemp(res);

  },[emailCreateData]);

  const changeModelViewData = (val) => {
    axios.post("/user/zoom/status",{sjp_mail:val?.personal_details?.uniemail,emp_No:val?.emp_no})
      .then(async function (response) {
        if(response.status === 200){
            var data = {
              name: val?.personal_details?.NameInitials,
              empNo: val?.emp_no,
              email: val?.personal_details?.uniemail,
              mobileNo: val?.personal_details?.StuTeleMobile,
              old_sjp_email:  val?.personal_details?.uniemail,
              zoomStatus: response.data?.zoomStatus,
              accountStatus:response.data?.accountState,
          };
          setModelViewData(data);
          setIsModelOpen(true);
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
    
   
  }

  const data = [{ id: 1, firstName: 'John', lastName: 'Doe' },{ id: 2, firstName: 'John', lastName: 'Doe' }];

   const renderRowExpanded = rowData => {
    return (
      <div>
        <div
          style={{
            width: 60,
            height: 60,
            float: 'left',
            marginRight: 10,
            background: '#eee'
          }}
        >
          <img src={rowData.avatar} style={{ width: 60 }} />
        </div>
        <p>Email: {rowData.email}</p>
        <p>Phone: {rowData.phone}</p>
      </div>
    );
  };

  const handleChangeSearch = (val) => {
    setSearchText(val.target.value);
    var searchTerm = val.target.value;

    if (searchTerm.length === 0) {
      //alert("")
      setEmailCreateData([]);
      setEmailCreateDataTemp([]);
    }else{
      axios.post("/usjnet-student-member-details",{stuRegNo:searchTerm})
      .then(async function (response) {
       // alert("2")
        if(response.status === 200){
            setEmailCreateData(response.data);
            setEmailCreateDataTemp(response.data);
            setIsLoading(false);
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
    }

  

    // setSearchText(val.target.value);
    // var searchTearm = val.target.value;
    // var filteredData = [];
    //  filteredData = emailCreateDataTemp.filter(item =>
    // item.emp_no.toLowerCase().includes(searchTearm.toLowerCase())
    // );
    // if(filteredData.length === 0){
    //   filteredData = emailCreateDataTemp.filter(item =>
    //   item?.personal_details?.NIC.toLowerCase().includes(searchTearm.toLowerCase()));
    // }
    // if(filteredData.length === 0){
    //   filteredData = emailCreateDataTemp.filter(item =>
    //   item?.personal_details?.TeleMobile.toLowerCase().includes(searchTearm.toLowerCase()));
    // }
    // if(filteredData.length === 0){
    //   filteredData = emailCreateDataTemp.filter(item =>
    //   item?.personal_details?.NameInitials.toLowerCase().includes(searchTearm.toLowerCase()));
    // }
    // setEmailCreateData(filteredData);
  }

  return (
    <>
    <div class="product__discount">
        <div class="section-title product__discount__title">
            <h2>USJNet Student Members</h2>
            <div style={{ padding:20 }}></div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
            <div style={{ maxWidth: 400, width: '100%' }}>
              <TextField
                label="Search"
                variant="outlined" // This makes the input outlined
                value={searchText}
                onChange={(val) => handleChangeSearch(val)}
                fullWidth // Optional: to make the input take full width
              />
            </div>
          </div>
            
            <DataTableComponent columns={columns} data={rowData} />
        </div>
        
    </div>
    <CommonModel reLogAccount={reLogAccount} type="student" modelViewData={modelViewData} setIsModelOpen={setIsModelOpen} openModel = {isModelOpen} handleSubmitUserUpdate={handleSubmitUserUpdate}/>
    </>
     
  )
}
