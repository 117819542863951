import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from '../pages/home-page';
import CreateAccountPage from '../pages/create-account-page';
import LoginPage from '../pages/login-page';
import ChangeAccountPasswordPage from '../pages/change-account-password-page';
import AdminPage from '../pages/admin-page';
import ProfilePage from '../pages/profile-page';
import LayoutComponent from '../components/layout/Layout';
import UnauthorizedComponent from '../components/unauthorized-component/unauthorized-component';
import BadRequestComponent from '../components/bad-request/bad-request-component';
import NotFoundComponent from '../components/not-found-component/not-found-component';
import RequireAuth from '../components/auth-component/requireAuth';
import useServices from '../hooks/useServices';
import useAuth from '../hooks/useAuth';
import Cookies from 'universal-cookie';
import UserLoginSSO from '../components/auth/user-login';
import UserCallbackSSO from '../components/auth/user-call-back';
import UserConnectSSO from '../components/auth/user-connect';
import DetailsPage from '../pages/details-page';
import ApplyApplicationPage from '../pages/apply-application-page';
import ResetAccountPasswordPage from '../pages/reset-account-password-page';
import WiFiPage from '../pages/wi-fi-page';
import EResourcePage from '../pages/eResourcePage';
import EduroamPage from '../pages/edurom-page';
import ScrollToTop from '../components/common/scrollToTop';
import DownloadPage from '../pages/download-page';
import WiFiCoveragePage from '../pages/wifi-coverage-page';

export default function AppRoute() {

const cookies = new Cookies(null, { path: '/' });
   const { auth, setAuth } = useAuth();
  //  const [ services] = useServices();
  //  const USERPRIVILAGES = services.USERPRIVILAGES;

  const location = useLocation();

  useEffect(() => {
    // Change the title based on the path
    if (location.pathname === '/') {
      document.title = 'Login | USJNet Sphere';
    } else if (location.pathname === '/home') {
      document.title = 'Home | USJNet Sphere';
    } else if (location.pathname === '/admin') {
      document.title = 'Admin | USJNet Sphere';
    }else if (location.pathname === '/create-account') {
      document.title = 'Create Account | USJNet Sphere';
    }else if (location.pathname === '/reset-account-password') {
      document.title = 'Reset Password | USJNet Sphere';
    }else if (location.pathname === '/change-account-password') {
      document.title = 'Change Password | USJNet Sphere';
    }else if (location.pathname === '/my-profile') {
      document.title = 'My Profile | USJNet Sphere';
    }else if (location.pathname === '/my-salary') {
      document.title = 'My Salary | USJNet Sphere';
    }else if (location.pathname === '/wifiPage') {
      document.title = 'Wi-Fi | USJNet Sphere';
    }else if (location.pathname === '/EResourcePage') {
      document.title = 'E-Resources | USJNet Sphere';
    }else if (location.pathname === '/EduroamPage') {
      document.title = 'Eduroam | USJNet Sphere';
    }else if (location.pathname === '/download') {
      document.title = 'Downloads | USJNet Sphere';
    } else {
      document.title = 'USJNet Sphere';
    }
  }, [location]);

  return (
    // <Routes>
    //     <Route path="/" element={<LoginPage />} />
    //     <Route path="/create-account" element={<CreateAccountPage />} />
    //     <Route path="/change-account-password" element={<ChangeAccountPasswordPage />} />
        // <Route path="/admin" element={<AdminPage />} />
        // <Route path="/home" element={<HomePage />} />
        // <Route path="/ProfilePage" element={<ProfilePage />} />
    // </Routes>
<>
 <ScrollToTop />
      <Routes>
         
        <Route path="/" element={<LayoutComponent />}>
       
        <Route exact path="/" element={<LoginPage />} />
         <Route exact path="/user-login" element={<UserLoginSSO />} />
         <Route exact path="/user_callback" element={<UserCallbackSSO />} />
         <Route path="/user_connect" component={<UserConnectSSO />} />
        <Route exact path="/create-account-test" element={<CreateAccountPage />} />
        <Route exact path="/create-account" element={<CreateAccountPage />} />
        <Route exact path="/reset-account-password" element={<ResetAccountPasswordPage />} />
        <Route exact path="/change-account-password" element={<ChangeAccountPasswordPage />} />
        <Route path="unauthorized" element={<UnauthorizedComponent />} />
        <Route path="badrequest" element={<BadRequestComponent />} />
        
        <Route element={<RequireAuth allowedRoles={["logged"]} />}>
        <Route path="*" element={<NotFoundComponent />} />       
         </Route>
         {/* <Route element={<RequireAuth allowedRoles={["logged"]} />}>  */}
            <Route path="/admin" element={<AdminPage />} /> 
         {/* </Route>  */}
         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/home" element={<HomePage />} />
         </Route>
         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/apply-application" element={<ApplyApplicationPage />} />
         </Route>
          <Route element={<RequireAuth allowedRoles={["logged"]} />}>
           <Route path="/my-salary" element={<DetailsPage />} />
          </Route>
         
         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/my-profile" element={<ProfilePage />} />      
         </Route>

         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/wifiPage" element={<WiFiPage />} />      
         </Route>

          <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/EResourcePage" element={<EResourcePage />} />      
         </Route>

         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/EduroamPage" element={<EduroamPage />} />      
         </Route>

         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/download" element={<DownloadPage />} />      
         </Route>

          <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/wi-fi-coverage" element={<WiFiCoveragePage />} />      
         </Route>

         </Route>
      </Routes>
</>

   
  )
}
