import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import BackImage from "../../../assets/banner.png";
import useServices from '../../../hooks/useServices';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 16px; /* Increased font size */

  &:hover {
    color: #990000;
    text-decoration: none;
    font-size: 16px; /* Increased hover font size */
    font-weight:600;
    cursor: pointer;
  }
`;

const BannerContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  //align-items: center;
  position: relative;
  background-image: url(${BackImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  height: 350px;
  

  @media (max-width: 1200px) {
    height: 300px; /* Adjust height for medium screens */
  }

  @media (max-width: 768px) {
    height: 250px; /* Adjust height for tablet screens */
    justify-content: center; /* Center content for smaller screens */
  }

  @media (max-width: 579px) {
    margin-top:20px;
    height: 220px; /* Adjust height for mobile screens */
    padding: 0 15px; /* Add some padding for mobile */
    background-image: none; /* Remove background image on mobile */
    background-position: top center; /* Adjust the background position for mobile */
  }
`;

const SectionContent = styled.section`
  max-width: 700px;
  margin-left: 7%; /* Align to the left */
  width: 100%; /* Ensure it takes full width up to max-width */
`;

const ListContainer = styled.div`
  margin: 0; /* Add some spacing between items */
  padding: 5px;
`;

export default function SectionTwoComponent({ initialData, isLoading }) {
  const [service] = useServices();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [approvalList, setApprovalList] = useState([]);
  const [recomandationList, setRecomandationList] = useState([]);

useEffect(()=>{
  if(initialData.length !== 0){
    const sortedApproval = initialData?.approvalList?.sort((a, b) => a.priorityNo - b.priorityNo);
    setApprovalList(sortedApproval);

    const sortedRecomandation = initialData?.recommendationList?.sort((a, b) => a.priorityNo - b.priorityNo);
    setRecomandationList(sortedRecomandation);
  }

},[initialData]);

  const LoadIframe = (url, IsPassRegNoWithURL,IsPassEmailWithURL) => {
    if (IsPassRegNoWithURL) {
      service.setIFrameURL(url + cookies.get('empNo'));
      navigate("/apply-application");
    }else if (IsPassEmailWithURL) {
      service.setIFrameURL(url + cookies.get('sjpEmail'));
      navigate("/apply-application");
    } else {
      service.setIFrameURL(url);
      navigate("/apply-application");
    }
  }

  return (
    <>
      <BannerContainer>
        <Fragment>
          <SectionContent>
            <div className="container mt-5">
              <div className="row">
                {approvalList.length > 0 && (
                  <div className="col-sm-6">
                    <div>
                      <h4 style={{ marginLeft: 5 }}>Approvals</h4>
                      {!isLoading && (
                        <>
                          {approvalList?.map((item) => (
                            <ListContainer key={item?.name}>
                              <StyledLink onClick={() => LoadIframe(item?.navigate, item?.IsPassRegNoWithURL,item?.IsPassEmailWithURL)}>
                                  {item?.isShowCount ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ paddingRight: 20 }}>{item?.name}</span>
                                        <Badge color="error" badgeContent={`${item.count}`}>
                                          <span />
                                        </Badge>
                                      </div>
                                    ) :(
                                      <span>{item?.name}</span>
                                    )}
 
                              </StyledLink>
                            </ListContainer>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}
                
                {recomandationList.length > 0 && (
                    <div className="col-sm-6">
                      <div>
                        <h4 style={{ marginLeft: 5 }}>Recommendations</h4>
                        {!isLoading && (
                          <>
                            {recomandationList?.map((item) => (
                              <Fragment key={item?.name}>
                                {item?.ServiceType === 0 && (
                                  <ListContainer>
                                    <StyledLink onClick={() => LoadIframe(item?.navigate, item?.IsPassRegNoWithURL,item?.IsPassEmailWithURL)}>

                                    {item?.isShowCount ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ paddingRight: 20 }}>{item?.name}</span>
                                        <Badge color="error" badgeContent={`${item.count}`}>
                                          <span />
                                        </Badge>
                                      </div>
                                    ) :(
                                      <span>{item?.name}</span>
                                    )}
 
                                    </StyledLink>
                                  </ListContainer>
                                )}
                                {item?.ServiceType === 2 && (
                                  <ListContainer>
                                    <StyledLink target='_blank' href={item?.navigate}>
                                      
                                    {item?.isShowCount ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ paddingRight: 20 }}>{item?.name}</span>
                                        <Badge color="error" badgeContent={`${item.count}`}>
                                          <span />
                                        </Badge>
                                      </div>
                                    ) :(
                                      <span>{item?.name}</span>
                                    )}

                                    </StyledLink>
                                  </ListContainer>
                                )}
                              </Fragment>
                            ))}
                          </>
                        )}
                      </div>
                    </div>

                )}

                
              </div>
            </div>
          </SectionContent>
        </Fragment>
      </BannerContainer>
    </>
  );
}
