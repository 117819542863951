import React, { Fragment, useState } from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import { Button, Divider, Panel, Placeholder, RadioTile, RadioTileGroup, Steps } from 'rsuite'
import { Col, Form, Row } from 'react-bootstrap'
import StepsComponent from '../components/create-account-component/steps-component'
import UserVerificationForm from '../components/create-account-component/user-verification-form'
import UserDetailsPanel from '../components/create-account-component/user-details-panel'
import MobileVerificationComponent from '../components/create-account-component/mobile-verification-component'
import { Icon } from '@rsuite/icons';
import EmailVerificationComponent from '../components/create-account-component/email-verification-component'
import VerificationForm from '../components/create-account-component/verification-form'
import CreateNewPasswordForm from '../components/create-account-component/create-new-password-form'
import SuccessForm from '../components/create-account-component/success-form'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import axios from '../api/axios'
import ResetSuccessForm from '../components/create-account-component/reset-success-form'

export default function ResetAccountPasswordPage() {

const privateAxisos = useAxiosPrivate();
const [userData, setUserData] = useState({
    name: "",
    department: "",
    faculty: "",
    empNo: '',
    nic: '',
    otp:'',
    verifyOtp:'',
    verificationType:'',
    verification_status: false,
    stepCount: 0,
    email:"",
    userType: "",
    mobileNo: "",
    password: '',
    confirmPassword: '',
  });
const [showUserNotRegistedUser, setShowUserNotRegistedUser] = useState(false);
  // Step 2: Handle changes to the data
  const handleChange = (field, value) => {
    setUserData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const handleOnSubmitUserValidation = async () => {  

    var submitData = {
      'empNo' : userData?.empNo,
      'nic' :userData?.nic,
    };

    axios.post("/user-validation",submitData)
      .then(async function (response) {
        if(response.status === 200){
          if(response?.data?.state === "success"){
            if(response?.data?.code !== 'uv1000'){
              setShowUserNotRegistedUser(true);
            }
            handleChange("name",response?.data?.name);
            handleChange("department",response?.data?.department);
            handleChange("email",response?.data?.email);
            handleChange("faculty",response?.data?.faculty);
            handleChange("userType",response?.data?.userType);
            handleChange("mobileNo",response?.data?.mobileNo);
            handleChange('stepCount',1);
          }else{
            alert(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

  const handleOnSubmitSendVerification = async () => {
    
    var submitData = {
      'email' : userData?.email,
      'verificationType' :userData?.verificationType,
      'mobileNo': userData?.mobileNo, 
    };

    console.log(submitData)

    axios.post("/send-verification",submitData)
      .then(async function (response) {
     
        if(response.status === 200){
          if(response?.data?.state === "success"){
            // handleChange('stepCount',2);
            // handleChange('verification_status',true);
          }else{
            alert(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

   const handleOnSubmitVerifyVerification = async () => {
    
    var submitData = {
      'email' : userData?.email,
      'otp' :userData?.otp,
      'mobileNo': userData?.mobileNo, 
    };

    axios.post("/user-validation-otp",submitData)
      .then(async function (response) {
        if(response.status === 200){
          if(response?.data?.state === "success"){
            handleChange('stepCount',2);
            handleChange('verification_status',true);
          }else{
            alert(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }

   const handleOnSubmitChangePassword = async () => {
    
    var submitData = {
      'password' : userData?.password,
      'confirmPassword' :userData?.confirmPassword,
      'email' : userData?.email,
    };

    axios.post("/user-create-new-password",submitData)
      .then(async function (response) {
        if(response.status === 200){
          if(response?.data?.state === "success"){
            handleChange('stepCount',3);
          }else{
            alert(response?.data?.message);
          }
        }
      })
      .catch(function (error) {
        
        setTimeout(() => {
          console.log(error);
        }, 2000);
      });
  }


  return (
    <Fragment>
         <NavbarComponent />

    <section>
        <div class="container">
          <div><h3>Reset My Password</h3></div>
            <div class="row">
                <div className='mt-3'>
                    <StepsComponent stepCount={userData?.stepCount} />
                </div>
                    
                
                {userData?.stepCount === 0 && (<UserVerificationForm handleChange={handleChange} userDetails={userData} handleOnSubmitUserValidation={handleOnSubmitUserValidation} />)}

                {userData?.stepCount === 1 && (
                    <VerificationForm validationPath="changeAccountPassword" handleChange={handleChange} userDetails={userData} handleOnSubmitSendVerification={handleOnSubmitSendVerification} handleOnSubmitVerifyVerification={handleOnSubmitVerifyVerification} showVerificationMethod={true} showUserNotRegistedUser={showUserNotRegistedUser}/>
                )}

                {userData?.stepCount === 2 && (
                    <CreateNewPasswordForm handleOnSubmitChangePassword={handleOnSubmitChangePassword} handleChange={handleChange} userDetails={userData}/>
                )}
                
                {userData?.stepCount === 3 && (
                    <ResetSuccessForm  handleChange={handleChange} userDetails={userData}/>
                )}
                
                
            </div>
        </div>
    </section>

  
    </Fragment>
  )
}
