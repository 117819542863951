import React, { Fragment } from 'react'
import { Divider, Steps } from 'rsuite'

export default function StepsComponent({stepCount}) {
  return (
    <Fragment>
        <Steps current={stepCount} className='mb-3'>
                        <Steps.Item title="User Varification"  />
                        <Steps.Item title="Mobile/ Email Varification"  />
                        <Steps.Item title="Create New Password"  />
                        <Steps.Item title="Success"  />
                    </Steps>
                    <Divider />
    </Fragment>
  )
}
