import React, { Fragment, useEffect, useState } from 'react'
import { Panel, Placeholder, Row, Col } from 'rsuite';
import profileImage from '../../assets/profile.png';
import salaryImage from '../../assets/salary.png'
import eResourcesImage from '../../assets/e-resources.png';
import leaveImage from '../../assets/leave.png'
import wifiImage from '../../assets/wi-fi.png'
import VehicalPassImage from '../../assets/pass.png'
import allowanceImage from '../../assets/allowance.png'
import insuranceImage from '../../assets/insurance.png'
import adminImage from '../../assets/admin.png'
import hrms from '../../assets/hrms.png'
import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Configurations } from '../../config';
import taylor_and_francis from '../../assets/taylor_and_francis.png'
import oxford from '../../assets/oxford.png'
import Elsevierscopus from '../../assets/Elsevier-scopus.png'
import Jstor from '../../assets/Jstor.png'
import Emerald from '../../assets/emerald.png'

const CardContainer = styled`
  	display: flex;
    flex-direction: column;
`;


export default function EResourceCard() {

   const [isHovered, setIsHovered] = useState(false);
   const [panelId, setPanelId] = useState(0);
   const navigate = useNavigate();
const [accessItemList, setaccessItemList] = useState([]);




  // Function to handle hover state
  const handleMouseEnter = (id=0) => {
    setIsHovered(true);
    setPanelId(id)
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

const Card = ({props, cardImageURL ,cardImage=null,title="",pId=0,navigateLocation="/home"}) => (

  <Panel onClick={()=>{navigate(navigateLocation);}} onMouseEnter={()=>handleMouseEnter(pId)} onMouseLeave={handleMouseLeave} shaded {...props} bordered style={isHovered && panelId === pId ? { backgroundColor: '#f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',minWidth:"150px",cursor: 'pointer' } : {minWidth:"150px"}} className='mt-2 '>
    {/* <Placeholder.Paragraph /> */}
    <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}>
        <img src={cardImageURL} alt="logo" width={40} height={40} />
        <p style={isHovered && panelId === pId ? {fontSize:"14px",color: "#990000",textDecoration: 'none'}:{fontSize:"12px"}} className='mt-1'>{title}</p>
    </Row>
     
  </Panel>
);

const Link = ({props, cardImageURL ,cardImage=null,title="",pId=0,navigateLocation}) => (

  <a href={navigateLocation} target='_blank' style={{ textDecoration: 'none' }}>
  <Panel onMouseEnter={()=>handleMouseEnter(pId)} onMouseLeave={handleMouseLeave} shaded {...props} bordered style={isHovered && panelId === pId ? { backgroundColor: '#f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',minWidth:"150px",cursor: 'pointer',textDecoration: 'none' } : {minWidth:"150px"}} className='mt-2 '>
    {/* <Placeholder.Paragraph /> */}
    <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}>
      <div style={{ height:120 }}>
          <img src={cardImageURL} alt="logo" style={{ width:120,maxHeight:120 }} />
      </div>
        
        <p style={isHovered && panelId === pId ? {fontSize:"14px",color: "#990000",textDecoration: 'none'}:{fontSize:"12px"}} className='mt-1'>{title}</p>
    </Row>
     
  </Panel>
  </a>
);


  return (
    <Fragment>
        <Row className="d-flex justify-content-center flex-wrap">
    
        <>
        <Col  xs={12} sm={6} md={4} lg={4} className="justify-content-center">
          <Link cardImageURL={taylor_and_francis} pId={1} title="Taylor & Francis" target="_blank" navigateLocation={`https://www.tandfonline.com/action/ssostart?idp=https://idp.sjp.ac.lk/idp/shibboleth&redirectUri=https://www.tandfonline.com/`}/>
        </Col>

        <Col  xs={12} sm={6} md={4} lg={4} className="justify-content-center">
          <Link cardImageURL={Emerald} pId={3} title="Emerald" target="_blank" navigateLocation={`https://www.emerald.com/start-session?idp=https://idp.sjp.ac.lk/idp/shibboleth&return=`}/>
        </Col>

        <Col  xs={12} sm={6} md={4} lg={4} className="justify-content-center">
          <Link cardImageURL={oxford} pId={2} title="Oxford Journals" target="_blank" navigateLocation={`https://oup-sp.sams-sigma.com/shib?dest=https://academic.oup.com/journals/?login=true`}/>
        </Col>

        

        <Col xs={12} sm={6} md={4} lg={4} className="justify-content-center">
          <Link cardImageURL={Jstor} pId={4} title="Jstor" target="_blank" navigateLocation={`https://shibbolethsp.jstor.org/start?entityID=https%3A%2F%2Fidp.sjp.ac.lk%2Fidp%2Fshibboleth&site=jstor&dest=https%3A%2F%2Fwww.jstor.org%2F`}/>
        </Col>

        {/* <Col  xs={12} sm={6} md={4} lg={4} className="justify-content-center">
          <Link cardImageURL={Elsevierscopus} pId={5} title="Elsevier Scopus" target="_blank" />
        </Col> */}

        {/* <Col  md={6} sm={12}>
          <Link cardImageURL={} pId={5} title="dfadfasdf" navigateLocation={`/`}/>
        </Col> */}
        </>
 
        

    {/* {initialData?.accessItems?.myProfile && (
        <Col  md={6} sm={12}>
          <Card cardImage={profileImage} pId={1} title='My Profile' navigateLocation="/"/>
        </Col>
    )}

    {initialData?.accessItems?.mySalary && (
       <Col md={6} sm={12}>
      <Card cardImage={salaryImage} pId={2} title='My Salary' />
    </Col>
    )}

    {initialData?.accessItems?.eResources && (
        <Col md={6} sm={12}>
      <Card cardImage={eResourcesImage} pId={3} title='E-Resources' />
    </Col>
    )}

    {initialData?.accessItems?.myLeave && (
        <Col md={6} sm={12}>
      <Card cardImage={leaveImage} pId={4} title='My Leave' />
    </Col>
    )}

    {initialData?.accessItems?.usjnetWifi && (
        <Col md={6} sm={12}>
      <Card cardImage={wifiImage} pId={5} title='USJNet Wi-Fi' />
    </Col>
    )}

    {initialData?.accessItems?.hrms && (
       <Col md={6} sm={12}>
      <Card cardImage={hrms} pId={6} title='HRMS' />
    </Col>
    )}

    {initialData?.accessItems?.vehicalPass && (
        <Col md={6} sm={12}>
      <Card cardImage={VehicalPassImage} pId={7} title='Vehicle Pass' />
    </Col>
    )}

    {initialData?.accessItems?.researchAllowance && (
        <Col md={6} sm={12}>
      <Card cardImage={allowanceImage} pId={8} title='Research Allowance' />
    </Col>
    )}

    {initialData?.accessItems?.insurance && (
        <Col md={6} sm={12}>
      <Card cardImage={insuranceImage} pId={9} title='Insurance' />
    </Col>
    )}

    {initialData?.accessItems?.usjnetAdmin && (
       <Col md={6} sm={12}>
      <Card cardImage={adminImage} pId={10} title='USJNet Admin' navigateLocation="/admin"/>
    </Col>
    )} */}


   
    
    
    
    
    
    
    
    
    
    
    
  </Row>
    </Fragment>
  )
}
