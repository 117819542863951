import React, { Fragment } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Form } from 'react-router-dom'
import { Divider, Panel, Placeholder, Steps } from 'rsuite'

export default function UserDetailsPanel({userDetails}) {

const maskPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length < 7) {
    return phoneNumber; // Handle short phone numbers appropriately
  }
  return phoneNumber.slice(0, 3) + '****' + phoneNumber.slice(7);
};

const maskEmail = (email) => {
  const [localPart, domainPart] = email.split('@');
  if (localPart.length <= 2) {
    return email; // If the local part is too short to mask, return it as is
  }
  const maskedLocalPart = localPart[0] + localPart[1] + '******' + localPart.slice(-2);
  return `${maskedLocalPart}@${domainPart}`;
};

  return (
    <Fragment>
        <Panel header="Details" shaded>
                        {/* <Placeholder.Paragraph />
                        <Placeholder.Paragraph />
                        <Placeholder.Paragraph />
                        <Placeholder.Paragraph />
                        <Placeholder.Paragraph /> */}

                       <Container>

                        {userDetails?.empNo !== "" && (
                        <Row >
                          <Col style={{ color:'#FFBB70' }} sm={4}>Emp/Reg No</Col>
                          <Col className="text-left">{userDetails?.empNo}</Col>
                        </Row>
                      )}

                      {userDetails?.nic !== "" && (
                        <Row className='mt-3'>
                          <Col style={{ color:'#FFBB70' }} sm={4}>NIC No</Col>
                          <Col className="text-left">{userDetails?.nic}</Col>
                        </Row>
                      )}

                        {userDetails?.name !== "" && (
                          <Row className='mt-3'>
                          <Col style={{ color:'#FFBB70' }} sm={4} >Name</Col>
                          <Col className="text-left">{userDetails?.name}</Col>
                        </Row>
                        )}
                        
                      {userDetails?.department !== "" && (
                        <Row className='mt-3'>
                          <Col style={{ color:'#FFBB70' }} sm={4}>Department</Col>
                          <Col className="text-left">{userDetails?.department}</Col>
                        </Row>
                      )}

                      {userDetails?.faculty !== "" && (
                        <Row className='mt-3'>
                          <Col style={{ color:'#FFBB70' }} sm={4}>Faculty</Col>
                          <Col className="text-left">{userDetails?.faculty}</Col>
                        </Row>
                      )}

                      

                      {userDetails?.email !== "" && userDetails?.userType === "emp" && (
                        <Row className='mt-3'>
                          <Col style={{ color:'#FFBB70' }} sm={4}>Email</Col>
                          <Col className="text-left">{maskEmail(userDetails?.email)}</Col>
                        </Row>
                      )}

                      {userDetails?.mobileNo !== "" && (
                        <Row className='mt-3'>
                          <Col style={{ color:'#FFBB70' }} sm={4}>Mobile No</Col>
                          <Col className="text-left">{maskPhoneNumber(userDetails?.mobileNo)}</Col>
                        </Row>
                      )}
                       
                      </Container>
                        
                    </Panel>
    </Fragment>
  )
}
