import React from "react";
import styled from "styled-components";
import NavbarComponent from "../components/navbar-component/navbar-component";
import { Breadcrumb } from "rsuite";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../api/axios";
import { Configurations } from "../config";
import FooterComponent from "../components/common/footer-component/footer-component";

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  color: #990000;
  font-size: 1.5em;
  font-family: Arial, sans-serif;

`;

const List = styled.ul`
  margin-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 15px;
  font-family: Source Sans Pro;
`;

const Link = styled.a`
  color: #990000;
  text-decoration: none;
  font-family: Arial, sans-serif;

  &:hover {
    text-decoration: underline;
  }
`;

const ContactInfo = styled.div`
  margin-top: 20px;
  font-weight: bold;
`;

const ContactDetails = styled.p`
  margin: 5px 0;
  font-family: Arial, sans-serif;
`;

// New styled components for the additional content
const HelpSubtitle = styled.h3`
  color: #990000;
  font-size: 1.3em;
  margin-top: 20px;
`;

const HelpList = styled.ul`
  
`;

const ImportantLinksSubtitle = styled.h3`
  color: #990000;
  font-size: 1.3em;
  margin-top: 20px;
`;
const Paragraph = styled.p`
  font-size: 15px;
`;

function WiFiPage() {
  const navigate = useNavigate();
  return (
    <>
    <NavbarComponent />
    <div class="container">
      <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} >
            Wi-Fi
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
    </div>
    <Container>
        
      <Title>Wi-Fi Access - University of Sri Jayewardenepura</Title>

      <Section>
        <Subtitle>USJNet</Subtitle>

      <Paragraph>
            USJNet Wi-Fi network provides access to all University websites, the
            full range of Library resources and all non-University websites.
      </Paragraph>

      <Paragraph>
            USJNet is recommended for all current staff and students.
      </Paragraph>

     
      </Section>

      <Section>
        <Subtitle>Connecting to the USJNet Wi-Fi</Subtitle>
        <Paragraph>
          All the staff members and the students of the University who have obtained a{" "}
          <strong>USJNet ID</strong> and a <strong>Password</strong> can connect to the USJNet Wi-Fi.
        </Paragraph>

      <Paragraph>
           How to configure my <strong>mobile</strong> to access USJNet Wi-Fi? (
            <Link target="_blank" href={`${Configurations.baseUrl}/assets/usjnet_doc/2022.09.09_User_Guide_to_Configure_Your_Mobile.pdf`}>Download the User Guide</Link>)
      </Paragraph>


      <Paragraph>
           How to configure my <strong>laptop</strong> to access USJNet Wi-Fi? (
            <Link target="_blank" href={`${Configurations.baseUrl}/assets/usjnet_doc/2022.09.09_User_Guide_to_Configure_Your_Laptop.pdf`}>Download the User Guide</Link>)
      </Paragraph>


       
        
      </Section>

      <Section>
        <Subtitle>Wireless access for conference guests</Subtitle>
        <Paragraph>
          If you are organising a conference and want your guests to have wireless internet access
          for the duration of the conference please contact:
        </Paragraph>
        <ContactInfo>
          <ContactDetails>Centre for IT Services</ContactDetails>
          <ContactDetails>Intercom: 8756, 8757</ContactDetails>
        </ContactInfo>
      </Section>

      {/* Additional Content */}
      <Section>
        <HelpSubtitle>USJNet Wi-Fi network help</HelpSubtitle>



            <Paragraph>
           If you are having problems connecting to the wireless network, check your wireless
            connection is turned on.
            </Paragraph>

             <Paragraph>
           If you are still having problems after checking your wireless connection or not
            connecting after entering the password, please come to the CITS and contact the
            Network Manager.
            </Paragraph>


      </Section>

     
    </Container>
    <FooterComponent />
    </>
    
  );
}

export default WiFiPage;
