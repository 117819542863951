import React, { Fragment, useState } from 'react'
import { Button, RadioTile, RadioTileGroup} from 'rsuite'
import { Col, Form, Row } from 'react-bootstrap'
import UserDetailsPanel from './user-details-panel';

export default function ChangePasswordForm({handleOnSubmitChangePassword,handleChange,userDetails}) {

      const [validated, setValidated] = useState(false);
       const [errors, setErrors] = useState({
    password: '',
    confirmPassword: '',
    currentPassword:'',
  });
    const onSubmitAction = (event) => {
        event.preventDefault();
        if (validateForm()) {
            handleOnSubmitChangePassword();
        setValidated(true);
        } else {
        setValidated(false);
        }
        
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!userDetails.currentPassword) {
        valid = false;
        newErrors.currentPassword = 'Current Password is required';
        }

        if (!userDetails.password) {
        valid = false;
        newErrors.password = 'Password is required';
        }

        if (!userDetails.confirmPassword) {
        valid = false;
        newErrors.confirmPassword = 'Confirm Password is required';
        }

        if (userDetails.confirmPassword !== userDetails.password) {
        valid = false;
        newErrors.confirmPassword = 'Not Match the Password';
        }

        setErrors(newErrors);
        return valid;

    };

    const onValueChange = (event) => {
    const { name, value } = event.target;

    handleChange(name,value);

    // Clear error when user starts typing
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  return (
   <Fragment>
       <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                        <Form noValidate validated={false} onSubmit={onSubmitAction}>

                         <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                            Current Password
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.currentPassword} type="password" name='currentPassword' placeholder="Current Password" onChange={onValueChange} />
                            <p style={{ color:"red",fontSize:12 }}>{errors.currentPassword}</p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                            New Password
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.password} type="password" name='password' placeholder="New Password" onChange={onValueChange} />
                            <p style={{ color:"red",fontSize:12 }}>{errors.password}</p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                            Confirm Password
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.confirmPassword} type="password" name='confirmPassword' placeholder="Confirm Password" onChange={onValueChange}/>
                            <p style={{ color:"red",fontSize:12 }}>{errors.confirmPassword}</p>
                            </Col>
                        </Form.Group>
                        
                       

                        <Form.Group as={Row} className="mb-3">
                            <Col>
                                {/* Empty space to push the button to the end */}
                            </Col>
                            <Col xs="auto">
                                <Button  style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="submit">Next</Button>
                            </Col>
                            </Form.Group>
                        </Form>
                                  
                            </Col>
                    </Row>
        </Row>
    </Fragment>
  )
}
