import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Header, Content, Nav, Navbar, Avatar, Icon, Panel, Row, Col, Breadcrumb } from 'rsuite';
import NavbarComponent from '../components/navbar-component/navbar-component';
import axios from '../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import { Padding } from '@mui/icons-material';
import useServices from '../hooks/useServices';
import FooterComponent from '../components/common/footer-component/footer-component';


const NavbarBrand = styled.a`
  color: white;
  font-size: 24px;
  margin: 0 20px;
`;

const StyledAvatar = styled(Avatar)`
  width: 120px; /* Adjust as needed */
  height: 120px; /* Adjust as needed */
  overflow: hidden;
  border-radius: 50%; /* Makes the avatar circular */
  background-color: #f0f0f0; /* Optional: background color for better visibility */

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const StyledPanel = styled(Panel)`
  text-align: center;
  // margin: 10px;
  // padding: 20px;
`;

const StyledPanel2 = styled(Panel)`
  text-align: left;
  // margin: 10px;
  // padding: 20px;
`;

const StyledH3 = styled.h3`
  text-align: center;
`;

const StyledH32 = styled.h3`
  text-align: center;
  color: #c49923;
`;

const StyledP = styled.p`
  text-align: center;
`;

const StyledH4 = styled.h4`
  margin-top: 20px;
`;

const StyledButton = styled.button`
  display: inline-block;
  margin: 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ProfilePage = () => {

   const [isLoading, setIsLoading] = useState(true);
   const [userDetails, setUserDetails] = useState([]);
   const navigate = useNavigate();
   const services = useServices();

useEffect(()=>{

  setIsLoading(true);
  axios.get("/user/profile-data")
      .then(async function (response) {
        if(response.status === 200){
            setUserDetails(response?.data)
        }
      })
      .catch(function (error) {
      
        setTimeout(() => {
        //  console.log(error);
        }, 2000);
      });
},[])

  return (
    <>
    <NavbarComponent />
     <div class="container">
      <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item  >
            Profile
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
   
      
      <Content>
        <Row>
          <Col xs={24} md={8}>
            <StyledPanel bordered>
              <div style={{ justifyContent: 'center',alignItems: 'center' }}>
              <StyledAvatar  size="xxl"  src={`https://hrms.sjp.ac.lk/backend/dist/img/profile/${userDetails.employee_no}.jpg`}  />
              </div>
              <StyledH32>{userDetails.employee_no}</StyledH32>
              <StyledH3>{userDetails.title} {userDetails.initials} {userDetails.last_name}</StyledH3>
              <StyledP>{userDetails.designation_name} {userDetails.staff_grade}</StyledP>
              <StyledP>{userDetails.email}</StyledP>
              <StyledP>{userDetails.mobile_no}</StyledP>
              {/* <StyledButton>Send Message</StyledButton>
              <StyledButton>Contacts</StyledButton> */}
            </StyledPanel>
          </Col>
          <Col xs={24} md={16}>
            <StyledPanel2 bordered>
              <StyledH4>Personal Details</StyledH4>
              <div style={{ padding:10 }}></div>
              <p><strong>Full Name: </strong> {userDetails.name_denoted_by_initials} {userDetails.last_name}</p>
              <p><strong>NIC: </strong> {userDetails.nic}</p>
              <p><strong>Permanent Address: </strong> {userDetails.permanent_add1}, {userDetails.permanent_add2}{userDetails.permanent_add3 !== "" && (",")}{userDetails.permanent_add3}, {userDetails.pm_city_name}</p>
              <p><strong>Postal Address: </strong> {userDetails.postal_add1}, {userDetails.postal_add2}{userDetails.postal_add3 !== "" && (", ")}{userDetails.postal_add3}, {userDetails.po_city_name}</p>
              <p><strong>Date of Birth: </strong> {services[0].dateFormatDayMonthYear(userDetails.date_of_birth)}</p>
              <p><strong>Gender: </strong> {userDetails.gender}</p>
              <p><strong>Civil Status: </strong> {userDetails.civil_status}</p>
              <p><strong>Race: </strong> {userDetails.race}</p>
              <p><strong>Religion: </strong> {userDetails.religion}</p>
              <p><strong>Faculty: </strong> {userDetails.faculty_name}</p>
              <p><strong>Department: </strong> {userDetails.department_name}</p>
              {/* <StyledH4>Skills</StyledH4>
              <p>Branding, UX/UI Design, Packaging, Print & Editorial</p>
              <StyledH4>Contact Information</StyledH4>
              <p>Phone: +1 123 456 7890</p>
              <p>Email: hello@jeremyrose.com</p>
              <p>Site: www.jeremyrose.com</p>
              <StyledH4>Basic Information</StyledH4>
              <p>Birthday: June 5, 1992</p>
              <p>Gender: Male</p> */}
            </StyledPanel2>
          </Col>
        </Row>
      </Content>
     </div>
     <FooterComponent />
    </>
    
  );
};

export default ProfilePage;
